import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const SimilarStocksTable = ({ symbol }) => {
  const [focusStock, setFocusStock] = useState(null);
  const [similarStocks, setSimilarStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const normalizedSymbol = symbol.toUpperCase();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [focusResponse, allStocksResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/getRanking/${normalizedSymbol}`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/getStocks`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          })
        ]);

        const { canslim, magicElixir, diamond } = focusResponse.data;

        const focusStockData = {
          symbol: normalizedSymbol,
          canslimScore: canslim?.score || 0,
          magicElixirScore: magicElixir?.score || 0,
          diamondScore: diamond?.score || 0,
        };
        setFocusStock(focusStockData);

        const allStocks = Array.isArray(allStocksResponse.data) ? allStocksResponse.data : [];

        const similarStocksData = allStocks
          .filter(stock => stock.symbol !== normalizedSymbol)
          .map(stock => ({
            ...stock,
            similarityScore: calculateSimilarity(focusStockData, stock),
          }))
          .sort((a, b) => b.similarityScore - a.similarityScore)
          .slice(0, 5);

        setSimilarStocks(similarStocksData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [normalizedSymbol]);
  
  const calculateSimilarity = (focus, stock) => {
    const canslimDiff = Math.abs(focus.canslimScore - stock.canslimScore) / 6;
    const magicElixirDiff = Math.abs(focus.magicElixirScore - stock.magicElixirScore) / 100;
    const diamondDiff = Math.abs(focus.diamondScore - stock.diamondScore) / 100;
    return 1 - (canslimDiff + magicElixirDiff + diamondDiff) / 3;
  };

  const calculatePercentChange = (focusValue, stockValue) => {
    if (focusValue === 0) return 0; // Prevent division by zero
    return ((stockValue - focusValue) / Math.abs(focusValue)) * 100;
  };

  const renderMetricWithChange = (focusValue, stockValue) => {
    const percentChange = calculatePercentChange(focusValue, stockValue);
    const isPositive = percentChange >= 0;

    return (
      <span>
        {stockValue.toFixed(2)}{' '}
        <span
          style={{
            fontSize: '0.8em',
            color: isPositive ? 'green' : 'red',
            marginLeft: '4px',
          }}
        >
          ({isPositive ? '+' : ''}
          {percentChange.toFixed(2)}%)
        </span>
      </span>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="similar-stocks-container">
      <h3 className="similar-stocks-header">Similar Stocks Profile</h3>
      <div className="similar-stocks-table">
        <table>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>CANSLIM Score</th>
              <th>Magic Elixir</th>
              <th>Diamond Score</th>
              <th>Similarity</th>
            </tr>
          </thead>
          <tbody>
            {focusStock && (
              <tr className="focus-stock">
                <td>{focusStock.symbol}</td>
                <td>{focusStock.canslimScore.toFixed(2)}</td>
                <td>{focusStock.magicElixirScore.toFixed(2)}</td>
                <td>{focusStock.diamondScore.toFixed(2)}</td>
                <td>-</td>
              </tr>
            )}
            {similarStocks.map(stock => (
              <tr key={stock.symbol}>
                <td>
                  <Link to={`/stock/${stock.symbol}`}>{stock.symbol}</Link>
                </td>
                <td>
                  {renderMetricWithChange(focusStock.canslimScore, stock.canslimScore)}
                </td>
                <td>
                  {renderMetricWithChange(focusStock.magicElixirScore, stock.magicElixirScore)}
                </td>
                <td>
                  {renderMetricWithChange(focusStock.diamondScore, stock.diamondScore)}
                </td>
                <td>{(stock.similarityScore * 100).toFixed(1)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SimilarStocksTable;

