import React from 'react';
import { 
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea
} from 'recharts';

const MagicElixirPlot = ({ symbol, stockData, rankings }) => {  // Use server-calculated values directly
  console.log('Raw stockData:', stockData);
  console.log('MagicElixirPlot received props:', {
    symbol,
    stockData,
    rankings
  });
  const focusPoint = [{
    symbol,
    rsRating: Number(stockData?.rsRating) || 0,
    earningsGrowth: Number(stockData?.earningsGrowth) || 0,
    salesGrowth: Number(stockData?.salesGrowth) || 0,
    currentPrice: Number(stockData?.currentPrice) || 0,
    magicElixirScore: Number(stockData?.magicElixirScore) || 0,
    liquidityRating: Number(stockData?.liquidityRating) || 0,
    floatScore: Number(stockData?.floatScore) || 0,
    volumeScore: Number(stockData?.volumeScore) || 0,
    sharesFloat: Number(stockData?.sharesFloat) || 0,
    volume: Number(stockData?.volume) || 0
  }];

  console.log('Focus point data:', focusPoint[0]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <div className="bg-white p-4 border border-gray-200 rounded-lg shadow-lg max-w-xs">
            <p className="symbol font-bold text-lg mb-2">{data.symbol}</p>
            <p>
              <strong>Price:</strong> ${(data.currentPrice || 0).toFixed(2)}
              {data.currentPrice < 15 && (
                <span className="text-red-500 ml-2">⚠️ Low Price Stock</span>
              )}
            </p>
            <p><strong>Price Performance:</strong> {data.rsRating.toFixed(0)}</p>
            <p><strong>Earnings Growth:</strong> {data.earningsGrowth.toFixed(0)}%</p>
            <p><strong>Sales Growth:</strong> {data.salesGrowth.toFixed(0)}%</p>
            <p><strong>Liquidity Rating:</strong> {data.liquidityRating.toFixed(1)}</p>
            
            {/* Highlight section */}
            <div style={{ 
              backgroundColor: '#e5e7eb',
              padding: '8px 12px',
              borderRadius: '4px',
              marginTop: '8px',
              display: 'grid',
              gap: '4px'
            }}>
              <p>
                <strong>Magic Elixir Score:</strong> {rankings.magicElixir.score.toFixed(2)}
              </p>
              <p>
                <strong>Float Score:</strong> {data.floatScore.toFixed(1)}
              </p>
              <p>
                <strong>Volume Score:</strong> {data.volumeScore.toFixed(1)}
              </p>
            </div>

            {data.currentPrice < 15 && (
              <p className="text-red-500 mt-2 text-sm">
                Magic Elixir Score penalized by 75% due to low share price
              </p>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const MEDIAN_EPS = 44;
  const RS_THRESHOLD = 80;
  const MAX_EPS = Math.max(focusPoint[0].earningsGrowth * 1.2, 200);

  return (
    <div className="mt-8">
      <h3 className="text-xl font-semibold mb-4">Magic Elixir Analysis</h3>
      
      {/* Interpretation guide */}
      <div className="text-sm text-gray-600 mb-4">
        <p>The Magic Elixir is a term coined by hedge-fund manager Jim Roppel, based on his direct experience with William J. O'Neil. According to Jim, Bill O'Neil was always in stocks with triple-digit earnings, triple-digit sales, good price performance, and big liquidity in order to attract sustained instutional buying for large price moves. This plot will help you understand if a stock's characteristics meet these criteria.</p>
      </div>

      {/* Position summary */}
      <div className="mb-4 p-3 bg-gray-50 rounded-lg">
  <p className="font-medium">
    {symbol}'s Magic Elixir score is{' '}
    <span className="font-semibold">{rankings?.magicElixir?.score.toFixed(2)}</span>, which is ranked{' '}
    <span className="font-semibold">{rankings?.magicElixir?.rank}</span> out of all{' '}
    <span className="font-semibold">{rankings?.magicElixir?.totalStocks}</span> stocks, putting it in the{' '}
    <span className="font-semibold">{rankings?.magicElixir?.percentile?.toFixed(1)}th</span> percentile of all stocks.
  </p>
</div>

      <div style={{ width: '100%', height: 400 }} className="border border-gray-300">
        <ResponsiveContainer>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 60, left: 60 }}>
            <XAxis 
              type="number" 
              dataKey="rsRating" 
              name="Price Performance" 
              domain={[0, 100]}
              label={{ 
                value: 'Price Performance', 
                position: 'bottom', 
                offset: 0 
              }}
            />
            <YAxis 
              type="number" 
              dataKey="earningsGrowth" 
              name="Earnings Growth"
              domain={[0, MAX_EPS]}
              label={{ 
                value: 'Earnings Growth (%)', 
                angle: -90, 
                position: 'insideLeft' 
              }}
            />

            {/* Reference lines */}
            <ReferenceLine 
              x={RS_THRESHOLD} 
              stroke="green" 
              strokeDasharray="3 3"
              label={{ 
                value: '', 
                position: 'right',
                fill: '#666',
                fontSize: 12 
              }}
            />
            
            <ReferenceLine 
              y= {100} 
              stroke="#00ff00" 
              strokeDasharray="3 3"
              label={{ 
                value: `Triple digit EPS marker`, 
                position: 'right',
                fill: '#666',
                fontSize: 12,
                angle: -90 
              }}
            />

            {/* Sweet spot shading */}
            <ReferenceArea 
              x1={RS_THRESHOLD} 
              x2={100} 
              y1={100} 
              y2={MAX_EPS} 
              fill="#00ff00" 
              fillOpacity={0.1}
            />

            {/* Stock point */}
            <Scatter 
              data={focusPoint} 
              fill="#2563eb"
              shape="circle"
            />
            
            <Tooltip content={<CustomTooltip />} />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MagicElixirPlot;