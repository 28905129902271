import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const D3RadarChart = ({ data, width = 360, height = 360 }) => { 
  const svgRef = useRef();
  const tooltipRef = useRef();
  
  // Track if the animation has already played
  const [hasAnimated, setHasAnimated] = useState(false);

  const getRadarColor = (value) => {
    return value <= 50 
      ? d3.interpolateRgb("red", "yellow")(value / 50) 
      : d3.interpolateRgb("yellow", "green")((value - 50) / 50);
  };

  // Descriptions for each category
  const descriptions = {
    C: "Current Quarterly Earnings",
    A: "Annual Earnings Growth",
    N: "New Products/Leadership",
    S: "Supply and Demand",
    L: "Leader or Laggard",
    I: "Institutional Sponsorship"
  };

  useEffect(() => {
    const radius = Math.min(width, height) / 2 - 30;
    const angleSlice = (Math.PI * 2) / data.length;
    const labelDistance = 15;
    const labelOffset = 5;

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const rScale = d3.scaleLinear().domain([0, 100]).range([0, radius]);

    // Tooltip setup
    const tooltip = d3.select(tooltipRef.current)
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', '1px solid #ccc')
      .style('padding', '8px')
      .style('border-radius', '4px')
      .style('box-shadow', '0 2px 8px rgba(0,0,0,0.15)')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('max-width', '300px');

    // Draw polarity grid: concentric circles and radial lines
    const levels = 5;
    const midpointAngle = angleSlice / 2;
    const rotationAngle = (midpointAngle * 180) / Math.PI;

    for (let i = 1; i <= levels; i++) {
      const levelRadius = radius * (i / levels);

      svg.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', levelRadius)
        .style('fill', 'none')
        .style('stroke', '#aaa')
        .style('stroke-width', 1.2);

      // Add scale labels for each level (always rendered)
      const x = (levelRadius + labelOffset) * Math.cos(midpointAngle - Math.PI / 2);
      const y = (levelRadius + labelOffset) * Math.sin(midpointAngle - Math.PI / 2);

      svg.append('text')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', '0.35em')
        .attr('transform', `rotate(${rotationAngle}, ${x}, ${y})`)
        .style('font-size', '10px')
        .style('fill', '#666')
        .style('text-anchor', 'middle')
        .text((i * 100) / levels);
    }

    // Draw radial lines for each axis (always rendered)
    data.forEach((_, i) => {
      const angle = i * angleSlice;
      const x = radius * Math.cos(angle - Math.PI / 2);
      const y = radius * Math.sin(angle - Math.PI / 2);

      svg.append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', y)
        .style('stroke', '#aaa')
        .style('stroke-width', 1.2);
    });

    // Only animate the segments and points the first time
    if (!hasAnimated) {
      // Draw each segment with animation
      data.forEach((d, i) => {
        const angle = i * angleSlice;
        const nextAngle = (i + 1) * angleSlice;

        const color = getRadarColor(d.value);

        const x1 = rScale(d.value) * Math.cos(angle - Math.PI / 2);
        const y1 = rScale(d.value) * Math.sin(angle - Math.PI / 2);
        const x2 = rScale(data[(i + 1) % data.length].value) * Math.cos(nextAngle - Math.PI / 2);
        const y2 = rScale(data[(i + 1) % data.length].value) * Math.sin(nextAngle - Math.PI / 2);

        svg.append('polygon')
          .attr('points', `0,0 ${x1},${y1} ${x2},${y2}`)
          .attr('fill', color)
          .attr('fill-opacity', 0.6)
          .attr('stroke', color)
          .attr('stroke-width', 1.5)
          .style('opacity', 0)
          .transition()
          .duration(800)
          .delay(i * 200)
          .style('opacity', 1);
      });

      // Add points with transition animation
      data.forEach((d, i) => {
        const angle = i * angleSlice;
        const x = rScale(d.value) * Math.cos(angle - Math.PI / 2);
        const y = rScale(d.value) * Math.sin(angle - Math.PI / 2);
        const color = getRadarColor(d.value);

        svg.append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', 5)
          .attr('fill', color)
          .style('opacity', 0)
          .transition()
          .duration(800)
          .delay(i * 200)
          .attr('cx', x)
          .attr('cy', y)
          .style('opacity', 1);
      });

      // Mark animation as played
      setHasAnimated(true);
    }

    // Tooltip interaction for points (always rendered)
    data.forEach((d, i) => {
      const angle = i * angleSlice;
      const x = rScale(d.value) * Math.cos(angle - Math.PI / 2);
      const y = rScale(d.value) * Math.sin(angle - Math.PI / 2);
      const color = getRadarColor(d.value);

      svg.append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('r', 5)
        .attr('fill', color)
        .style('opacity', 1)
        .on('mouseover', () => {
          tooltip.style('opacity', 1);
          tooltip.html(`
            <div style="display: flex; align-items: center; margin-bottom: 8px;">
              <span style="width: 10px; height: 10px; border-radius: 50%; background-color: ${color}; display: inline-block; margin-right: 8px;"></span>
              <p style="font-weight: bold; font-size: 16px; margin: 0;">${d.category}: ${d.value.toFixed(2)}</p>
            </div>
            <p style="font-weight: bold; color: #555; font-size: 14px;">${descriptions[d.category]}</p>
            <p style="font-style: italic; color: #888; font-size: 12px;">This value is a quantification of the stock's strength in this category on a scale of 1-100. It is not a representation of any business metric.</p>
          `);
        })
        .on('mousemove', (event) => {
          tooltip
            .style('left', `${event.pageX + 10}px`)
            .style('top', `${event.pageY - 20}px`);
        })
        .on('mouseout', () => {
          tooltip.style('opacity', 0);
        });
    });

    // Render category labels (always rendered)
    data.forEach((d, i) => {
      const angle = i * angleSlice;
      const x = (radius + labelDistance) * Math.cos(angle - Math.PI / 2);
      const y = (radius + labelDistance) * Math.sin(angle - Math.PI / 2);

      svg.append('text')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', '0.35em')
        .style('font-size', '14px')
        .style('font-weight', 'bold')
        .style('text-anchor', 'middle')
        .style('fill', '#333')
        .text(d.category);
    });
  }, [data, width, height, hasAnimated]);

  return (
    <>
      <svg ref={svgRef}></svg>
      <div ref={tooltipRef} style={{ position: 'absolute', opacity: 0 }}></div>
    </>
  );
};

export default D3RadarChart;

