import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const OrientationHandler = ({ children }) => {
  const [isPortrait, setIsPortrait] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const sessionDismissed = sessionStorage.getItem('orientationDismissed');
    if (sessionDismissed) {
      setIsDismissed(true);
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const checkOrientation = () => {
      if (isMobile) {
        setIsPortrait(window.innerHeight > window.innerWidth);
      }
    };

    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  const handleDismiss = () => {
    setIsDismissed(true);
    sessionStorage.setItem('orientationDismissed', 'true');
  };

  if (isPortrait && !isDismissed) {
    return (
      <>
        {children}
        <div className="orientation-warning">
          <div className="orientation-backdrop" />
          <div className="orientation-content">
            <button 
              onClick={handleDismiss}
              className="dismiss-button"
              aria-label="Dismiss orientation warning"
            >
              <X size={20} />
            </button>
            <div className="phone-icon">
              <svg 
                width="48" 
                height="48" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <rect x="5" y="2" width="14" height="20" rx="2" ry="2" />
                <path d="M12 18h.01" />
              </svg>
              <svg 
                className="rotate-arrow" 
                width="48" 
                height="48" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
              >
                <path d="M3 12h18M9 6l-6 6 6 6" />
              </svg>
            </div>
            <h2 className="text-base sm:text-lg font-semibold mb-1 sm:mb-2">
              Please Rotate Your Device
            </h2>
            <p className="text-sm text-gray-600">
              This view is best experienced in landscape orientation
            </p>
          </div>
        </div>
      </>
    );
  }

  return children;
};

export default OrientationHandler;