import React, { useState } from 'react';
import { useAuth } from './contexts/AuthContext';

const AccountPage = () => {
  const { user, updatePassword } = useAuth();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      return setError('Passwords do not match');
    }
    try {
      setError('');
      setLoading(true);
      await updatePassword(newPassword);
      setMessage('Password updated successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to update password');
    }
    setLoading(false);
  };

  const handleManageSubscription = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/create-customer-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userId: user.uid  // Make sure to include this
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create portal session');
      }
  
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error accessing subscription management:', error);
      setError('Unable to access subscription management at this time');
    } finally {
      setLoading(false);
    }
  };
  
  // Format date to be more readable
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="subscription-container">
      <div className="subscription-card" style={{ maxWidth: '48rem' }}>
        <div className="subscription-header">
          <h2>Account Settings</h2>
          <p>{user.email}</p>
        </div>

        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
            {error}
          </div>
        )}

        {message && (
          <div className="bg-green-50 text-green-600 p-4 rounded-lg mb-6">
            {message}
          </div>
        )}

        {/* Subscription Details */}
        <div className="bg-gray-50 rounded-lg p-6 mb-8">
          <h3 className="text-lg font-semibold mb-4">Subscription Status</h3>
          
          <button
            onClick={handleManageSubscription}
            disabled={loading}
            className="subscription-button mt-4"
          >
            Manage Subscription
          </button>
        </div>

        {/* Password Update Form */}
        <div className="bg-white rounded-lg">
          <h3 className="text-lg font-semibold mb-4">Update Password</h3>
          <form onSubmit={handlePasswordUpdate} className="space-y-4">
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="focus:border-blue-600 focus:ring-2 focus:ring-blue-100"
              />
            </div>

            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="focus:border-blue-600 focus:ring-2 focus:ring-blue-100"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="subscription-button"
            >
              Update Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;