import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionPlans = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');

  const handleClick = async (priceId) => {
    try {
      setLoading(priceId);
      setError('');

      // Make sure we're using the correct URL and method
      const response = await fetch('/createCheckoutSession', {  
        method: 'POST',  // Explicitly declare POST method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId })  // Send data in body only with POST
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ error: 'Failed to create session' }));
        throw new Error(errorData.error || 'Failed to create checkout session');
      }

      const { sessionId } = await response.json();
      
      if (!sessionId) {
        throw new Error('No session ID received');
      }

      console.log('Session ID received:', sessionId); // Debug log

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to load');
      }

      const { error: stripeError } = await stripe.redirectToCheckout({ sessionId });
      
      if (stripeError) {
        throw new Error(stripeError.message);
      }

    } catch (err) {
      console.error('Checkout Error:', err);
      setError(err.message || 'Failed to start checkout process');
    } finally {
      setLoading('');
    }
  };

  const plans = [
    {
      id: 'monthly',
      title: 'Monthly',
      price: '$14.99',
      interval: '/month',
      billingText: 'Monthly billing',
      description: 'Base rate',
      priceId: 'price_basic_id_from_stripe'
    },
    {
      id: 'quarterly',
      title: 'Quarterly',
      price: '$12.99',
      interval: '/month',
      billingText: 'Billed $38.97 quarterly',
      description: 'Save 13%',
      priceId: 'price_premium_id_from_stripe',
      popular: true
    },
    {
      id: 'annual',
      title: 'Annual',
      price: '$9.99',
      interval: '/month',
      billingText: 'Billed $119.88 annually',
      description: 'Save 33%',
      priceId: 'price_premium_id_from_stripe'
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900">Choose Your Plan</h1>
        <p className="text-lg text-gray-600 mt-2">
          Enjoy a 14-day free trial on any subscription plan.
        </p>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6">
          {error}
        </div>
      )}

      <div className="grid md:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div 
            key={plan.id}
            className={`border rounded-lg p-6 ${
              plan.popular ? 'border-2 border-blue-500 relative transform scale-105' : ''
            }`}
          >
            {plan.popular && (
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm">
                  Most Popular
                </span>
              </div>
            )}
            <h2 className="text-2xl font-bold">{plan.title}</h2>
            <div className="mt-4">
              <span className="text-3xl font-bold">{plan.price}</span>
              <span className="text-gray-600">{plan.interval}</span>
            </div>
            <p className="text-gray-600 mt-2">{plan.billingText}</p>
            <p className="text-gray-500">{plan.description}</p>
            <button
              onClick={() => handleClick(plan.priceId)}
              disabled={!!loading}
              className={`mt-6 w-full py-2 px-4 rounded text-white 
                ${loading === plan.priceId ? 'opacity-50 cursor-not-allowed' : ''} 
                ${plan.popular ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-600 hover:bg-gray-700'}
              `}
            >
              {loading === plan.priceId ? 'Processing...' : 'Start 14-Day Free Trial'}
            </button>
          </div>
        ))}
      </div>

      <p className="text-center mt-8 text-gray-600">
        Free trial includes full access to all features.
      </p>
    </div>
  );
};

export default SubscriptionPlans;