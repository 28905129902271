import React from 'react';
import { Button, Menu, MenuItem, ListSubheader, Divider } from '@mui/material';
import { Download, FileText, FileSpreadsheet, FileJson, Printer } from "lucide-react";

const ExportDropdown = ({ onExportCSV, onExportXLSX, onExportTXT }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (exportFn) => {
    exportFn();
    handleClose();
  };

  return (
    <>
      <Button
        id="export-button"
        aria-controls={open ? 'export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        startIcon={<Download size={18} />}
        size="small"
        sx={{
          textTransform: 'none',
          color: 'rgba(0, 0, 0, 0.87)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderColor: 'rgba(0, 0, 0, 0.23)'
          }
        }}
      >
        Export
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
          dense: true
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListSubheader sx={{ lineHeight: '28px', fontSize: '0.75rem', color: 'text.secondary' }}>
          Choose Format
        </ListSubheader>
        
        <MenuItem onClick={() => handleExport(onExportCSV)} sx={{ fontSize: '0.875rem' }}>
          <FileText className="mr-2 h-4 w-4 text-gray-500" />
          Comma Delimited (.csv)
        </MenuItem>
        
        <MenuItem onClick={() => handleExport(onExportXLSX)} sx={{ fontSize: '0.875rem' }}>
          <FileSpreadsheet className="mr-2 h-4 w-4 text-gray-500" />
          Excel 97-2003 (.xls)
        </MenuItem>
        
        <MenuItem onClick={() => handleExport(onExportTXT)} sx={{ fontSize: '0.875rem' }}>
          <FileJson className="mr-2 h-4 w-4 text-gray-500" />
          Text (.txt)
        </MenuItem>

        <Divider sx={{ my: 1 }} />
        
        <ListSubheader sx={{ lineHeight: '28px', fontSize: '0.75rem', color: 'text.secondary' }}>
          Additional Options
        </ListSubheader>
        
        <MenuItem 
          disabled 
          sx={{ 
            fontSize: '0.875rem',
            color: 'text.disabled'
          }}
        >
          <Printer className="mr-2 h-4 w-4" />
          Print List
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportDropdown;