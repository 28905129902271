import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { 
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  ZAxis, 
  Tooltip, 
  ResponsiveContainer, 
  Cell, 
  ReferenceLine,
  ReferenceArea,
  Text 
} from 'recharts';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { Slider } from '@mui/material';
import ExportDropdown from './ExportDropdown';
import { Link, useNavigate } from 'react-router-dom';

const formatMarketCap = (marketCap) => {
    if (!marketCap) return '$0.00M';
    
    if (marketCap >= 1e12) {
      return `$${(marketCap / 1e12).toFixed(2)}T`;
    } else if (marketCap >= 1e9) {
      return `$${(marketCap / 1e9).toFixed(2)}B`;
    } else {
      return `$${(marketCap / 1e6).toFixed(0)}M`;
    }
  };


const MagicElixirStocks = () => {
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [medianEPS, setMedianEPS] = useState(0);
  const [minRSRating, setMinRSRating] = useState(0);
  const [epsRange, setEpsRange] = useState([100, 500]); // [min, max]
  const [maxEarningsGrowth, setMaxEarningsGrowth] = useState(0);
const [minEarningsGrowth, setMinEarningsGrowth] = useState(0);
const navigate = useNavigate();
  const [hoveredPoint, setHoveredPoint] = useState(null);

  const handlePointClick = useCallback((data) => {
    navigate(`/stock/${data.symbol}`);
  }, [navigate]);

  const handleEPSRangeChange = (event, newValue) => {
    setEpsRange(newValue);
  };

  const handleRSRatingChange = (event, newValue) => {
    setMinRSRating(newValue);
  };

  useEffect(() => {
    const fetchMagicElixirStocks = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getMagicElixirStocks`);
        
        // Filter stocks and ensure numeric values
        const filteredStocks = response.data.filter(stock => 
          !isNaN(stock.earningsGrowth) && 
          !isNaN(stock.salesGrowth) &&
          stock.earningsGrowth > 100 && 
          stock.salesGrowth > 100 &&
          stock.rsRating != null &&
          stock.magicElixirScore != null
        );

        // Calculate metrics with validation
        const earningsValues = filteredStocks
          .map(stock => stock.earningsGrowth)
          .filter(val => !isNaN(val));

        if (earningsValues.length > 0) {
          const sortedEPS = [...earningsValues].sort((a, b) => a - b);
          const mid = Math.floor(sortedEPS.length / 2);
          const medianEPSValue = sortedEPS.length % 2 !== 0 
            ? sortedEPS[mid]
            : (sortedEPS[mid - 1] + sortedEPS[mid]) / 2;

          const minEPS = Math.min(...earningsValues);
          const maxEPS = Math.max(...earningsValues);

          setMedianEPS(medianEPSValue);
          setMaxEarningsGrowth(maxEPS);
          setMinEarningsGrowth(minEPS);
          setEpsRange([minEPS, maxEPS]);
        } else {
          // Set default values if no valid data
          setMedianEPS(0);
          setMaxEarningsGrowth(500);
          setMinEarningsGrowth(0);
          setEpsRange([0, 500]);
        }
        
        setStocks(filteredStocks);
        
      } catch (error) {
        console.error('Error fetching Magic Elixir stocks:', error);
        setError('Failed to load Magic Elixir stocks: ' + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMagicElixirStocks();
  }, []);

  const visibleStocks = useMemo(() => {
    return stocks.filter(stock => 
      stock.rsRating >= minRSRating && 
      stock.earningsGrowth >= epsRange[0] &&
      stock.earningsGrowth <= epsRange[1]
    );
  }, [stocks, minRSRating, epsRange]);

  const columns = useMemo(() => [
    {
      name: 'Symbol',
      selector: row => row.symbol,
      sortable: true,
      cell: row => (
        <Link 
          to={`/stock/${row.symbol}`}
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          {row.symbol}
        </Link>
      ),
    },
    {
        name: 'Magic Elixir Score',
        selector: row => row.magicElixirScore,
        sortable: true,
        format: row => row.magicElixirScore.toFixed(2),
        id: 'magicElixirScore',
      },
      {
        name: 'Liquidity Rating',
        selector: row => row.liquidityRating,
        sortable: true,
        format: row => row.liquidityRating.toFixed(1),
      },
    {
      name: 'Earnings Growth',
      selector: row => row.earningsGrowth,
      sortable: true,
      format: row => `${(row.earningsGrowth || 0).toFixed(0)}%`,  
    },
    {
      name: 'Sales Growth',
      selector: row => row.salesGrowth,
      sortable: true,
      format: row => `${(row.salesGrowth || 0).toFixed(0)}%`,    },
    {
      name: 'Price Performance',
      selector: row => row.rsRating,
      sortable: true,
      format: row => row.rsRating.toFixed(0),
    },
    {
      name: 'Market Cap ($B)',
      selector: row => row.marketCap,
      sortable: true,
      format: row => formatMarketCap(row.marketCap),
        },
  ], []);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="symbol">{data.symbol}</p>
          <p>
            <strong>Price:</strong> ${(data.currentPrice || 0).toFixed(2)}
            {data.currentPrice < 15 && (
              <span className="text-red-500 ml-2">⚠️ Low Price Stock</span>
            )}
          </p>
          <p><strong>Price Performance:</strong> {(data.rsRating || 0).toFixed(0)}</p>
          <p><strong>Earnings Growth:</strong> {(data.earningsGrowth || 0).toFixed(0)}%</p>
          <p><strong>Sales Growth:</strong> {(data.salesGrowth || 0).toFixed(0)}%</p>
          <p><strong>Liquidity Rating:</strong> {(data.liquidityRating || 0).toFixed(0)}</p>
          <p><strong>Market Cap:</strong> {formatMarketCap(data.marketCap)}</p>
          <p style={{ 
            backgroundColor: '#e5e7eb', // gray-200 equivalent
            padding: '4px 8px',
            borderRadius: '4px',
            marginTop: '4px'
          }}>
            <strong>Magic Elixir Score:</strong> {(data.magicElixirScore || 0).toFixed(2)}
          </p>
          {data.currentPrice < 15 && (
            <p className="text-red-500 mt-1 text-sm">Score penalized due to low share price</p>
          )}
        </div>
      );
    }
    return null;
  };

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE'];

  const exportToCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," 
      + columns.map(col => col.name).join(",") + "\n"
      + visibleStocks.map(row => columns.map(col => row[col.selector(row)]).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "magic_elixir_stocks.csv");
    document.body.appendChild(link);
    link.click();
  };

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(visibleStocks);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Magic Elixir Stocks");
    XLSX.writeFile(workbook, "magic_elixir_stocks.xlsx");
  };

  const exportToTXT = () => {
    const txtContent = visibleStocks.map(stock => 
      `${stock.symbol}: Earnings Growth ${stock.earningsGrowth.toFixed(2)}%, Sales Growth ${stock.salesGrowth.toFixed(2)}%, Shares Float ${(stock.sharesFloat / 1000).toFixed(2)}M, RS Rating ${stock.rsRating.toFixed(2)}, Market Cap $${(stock.marketCap / 1e9).toFixed(2)}B, Magic Elixir Score ${stock.magicElixirScore.toFixed(2)}`
    ).join('\n');
    const blob = new Blob([txtContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'magic_elixir_stocks.txt';
    link.href = url;
    link.click();
  };

  if (isLoading) return <div>Loading Magic Elixir stocks...</div>;
  if (error) return <div>Error: {error}</div>;
  if (stocks.length === 0) return <div>No stocks found matching the Magic Elixir criteria.</div>;

  return (
    <div className="w-full p-4">
      <h2 className="text-2xl font-semibold mb-4">Magic Elixir Stocks</h2>
      <p className="mb-4">Based on his personal experience with William J. O'Neil, hedge fund manager Jim Roppel coined the term "Magic Elixir" to describe the characteristics of the stocks that Bill was consistently invested in, and made his fortune from. These are stocks that have triple digit earnings growth, triple digit sales growth, a strong foundation of productive price action, and in possession of the type of liquidity that can attract and support sustained institutional buying. </p>
      
      <div className="mb-4 text-sm text-gray-600">
        <p>We have derived a proprietary metric to evaluate a stock's compatability with these criteria, as well as some other basic growth stock best practices, such as being wary of low priced stocks. Stocks that score the most favorably will be observable inside the green Magic Elixir quadrant.</p>
        <p>Play with the sliders to expand the range on the plot.</p>
      </div>

      {/* RS Rating Slider */}
      <div className="mb-8 flex flex-col w-72">
  <div className="mb-2"> {/* Separate div for label */}
    Minimum Price Performance: {minRSRating}
  </div>
  <Slider
    value={minRSRating}
    onChange={handleRSRatingChange}
    min={0}
    max={100}
    step={1}
    sx={{
      width: '40%',
      '& .MuiSlider-thumb': {
        backgroundColor: '#3B82F6',
      },
      '& .MuiSlider-track': {
        backgroundColor: '#3B82F6',
      },
      '& .MuiSlider-rail': {
        backgroundColor: '#E5E7EB',
      }
    }}
  />
</div>


      {/* EPS Growth Slider */}
{/* EPS Growth Range Slider */}
<div className="mb-8 flex flex-col w-72">
  <div className="mb-2"> {/* Matched styling with RS slider */}
    EPS Growth: {epsRange[0].toFixed(0)}% - {epsRange[1].toFixed(0)}%
  </div>
  <Slider
    value={epsRange}
    onChange={handleEPSRangeChange}
    min={100}
    max={maxEarningsGrowth}
    step={10}
    sx={{
      width: '40%',
      '& .MuiSlider-thumb': {
        backgroundColor: '#3B82F6',
      },
      '& .MuiSlider-track': {
        backgroundColor: '#3B82F6',
      },
      '& .MuiSlider-rail': {
        backgroundColor: '#E5E7EB',
      }
    }}
  />
</div>
      
      <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 60, left: 60 }}>
            <defs>
              <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#00ff00" stopOpacity={0.1}/>
                <stop offset="100%" stopColor="#00ff00" stopOpacity={0.1}/>
              </linearGradient>
            </defs>

            <XAxis 
              type="number" 
              dataKey="rsRating" 
              name="Price Performance" 
              domain={[minRSRating, 100]}
              label={{ value: 'Price Performance', position: 'bottom', offset: 0 }}
            />
<YAxis 
  type="number" 
  dataKey="earningsGrowth" 
  name="Earnings Growth" 
  unit="%" 
  domain={[epsRange[0], epsRange[1]]}
  label={{ value: 'Earnings Growth (%)', angle: -90, position: 'insideLeft', offset: -10 }}
/>

<ZAxis 
  type="number" 
  dataKey="liquidityRating" // Changed from sharesFloat
  range={[2, 250]} 
  name="Liquidity Rating" // Updated name for clarity
/>
            
<ReferenceArea
  x1={80}
  x2={100}
  y1={100}
  y2={epsRange[1]}
  fill="url(#greenGradient)"
  fillOpacity={1}
  ifOverflow="visible"
/>
            
<ReferenceLine 
  x={80} 
  stroke="#ff0000" 
  strokeDasharray="3 3" 
  label={{ 
    value: 'The Magic Elixir Quadrant', 
    position: 'insideTopLeft',
    fill: '#666',
    fontSize: 12 
  }} 
/>
            <ReferenceLine 
              y={medianEPS} 
              stroke="#00ff00" 
              strokeDasharray="3 3" 
              label={{ 
                value: `Median EPS = ${medianEPS.toFixed(0)}%`, 
                position: 'start',
                offset: 15,
                fill: '#666',
                fontSize: 12,
                dy: -10 
              }} 
            />
            
            <Tooltip content={<CustomTooltip />} />
            
            <Scatter 
          data={visibleStocks} 
          onClick={(data) => handlePointClick(data)}
          onMouseEnter={(props) => {
            if (props && props.payload) {
              setHoveredPoint(visibleStocks.findIndex(s => s.symbol === props.payload.symbol));
            }
          }}
          onMouseLeave={() => setHoveredPoint(null)}
          cursor="pointer"
        >            
          {visibleStocks.map((entry, index) => (
  <Cell 
  key={`cell-${index}`} 
  fill={COLORS[index % COLORS.length]}
  opacity={hoveredPoint === index ? 1 : 0.8}
  r={hoveredPoint === index ? 8 : 5}  // Size of the dot
/>             
 ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4">Magic Elixir Stocks Table</h3>
        <div className="mb-4">
        <ExportDropdown 
    onExportCSV={exportToCSV}
    onExportXLSX={exportToXLSX}
    onExportTXT={exportToTXT}
  />        </div>
        <DataTable
          columns={columns}
          data={visibleStocks}
          pagination
          highlightOnHover
          responsive
          defaultSortFieldId="magicElixirScore"
          defaultSortAsc={false}
        />
      </div>
    </div>
  );
};

export default MagicElixirStocks;