import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { 
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  ZAxis, 
  Tooltip, 
  ResponsiveContainer, 
  Cell,
  ReferenceLine,
  ReferenceArea,
  Legend
} from 'recharts';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { debounce } from 'lodash';
import { Button } from '@mui/material';
import { Download, TableView, Description } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import ExportDropdown from './ExportDropdown';


const formatMarketCap = (marketCap) => {
  if (!marketCap) return '$0.00M';
  if (marketCap >= 1e12) return `$${(marketCap / 1e12).toFixed(2)}T`;
  if (marketCap >= 1e9) return `$${(marketCap / 1e9).toFixed(2)}B`;
  return `$${(marketCap / 1e6).toFixed(0)}M`;
};

const calculatePercentile = (values, percentile) => {
  const sorted = [...values].sort((a, b) => a - b);
  const index = Math.ceil((percentile / 100) * sorted.length) - 1;
  return sorted[index];
};

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE'];

const DiamondStocks = () => {
  const navigate = useNavigate();
  const [hoveredPoint, setHoveredPoint] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedSymbol, setHighlightedSymbol] = useState(null);
  const [thresholds, setThresholds] = useState({ 
    canslim75: 0,
    magicElixir75: 0,
    canslim90: 0,
    magicElixir90: 0,
    eliteCount: 0
  });

  const handlePointClick = useCallback((data) => {
    navigate(`/stock/${data.symbol}`);
  }, [navigate]);

    // Format tick values for CANSLIM score
    const formatCanslimTick = (value) => value.toFixed(1);

    // Format tick values for Magic Elixir score
    const formatMagicElixirTick = (value) => value.toFixed(0);

    const getAxisDomains = useMemo(() => {
      if (!stocks.length) return { min: 0, max: 6 };
      const minCanslim = Math.min(...stocks.map(stock => stock.canslimScore));
      return {
        min: Math.floor(minCanslim * 10) / 10, // Round down to nearest 0.1
        max: 6
      };
    }, [stocks]);
  
    // Custom tick count generator for CANSLIM score
    const getCanslimTicks = useMemo(() => {
      if (!thresholds.canslim75 || !getAxisDomains.min) return [];
      const min = getAxisDomains.min;
      const max = 6;
      const step = (max - min) / 4; // We want ~5 ticks
      return Array.from({ length: 5 }, (_, i) => min + (step * i));
    }, [thresholds.canslim75, getAxisDomains.min]);
  
    // Custom tick count generator for Magic Elixir score
    const getMagicElixirTicks = useMemo(() => {
      if (!thresholds.magicElixir75) return [];
      const min = Math.floor(thresholds.magicElixir75 / 10) * 10; // Round to nearest 10
      const max = 100;
      const step = (max - min) / 4; // We want ~5 ticks
      return Array.from({ length: 5 }, (_, i) => min + (step * i));
    }, [thresholds.magicElixir75]);

    useEffect(() => {
      const fetchDiamondStocks = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getDiamondStocks`);
          
          // Ensure we have an array to work with
          const allStocks = Array.isArray(response.data) ? response.data : [];
          
          // Filter valid stocks
          const validStocks = allStocks.filter(stock => 
            stock && 
            typeof stock === 'object' &&
            !isNaN(stock.magicElixirScore) && 
            stock.magicElixirScore > 0
          );
  
          if (validStocks.length === 0) {
            throw new Error('No valid stocks data found');
          }
  
          // Calculate percentiles from valid stocks
          const canslimScores = validStocks
            .map(stock => stock.canslimScore)
            .filter(score => !isNaN(score));
          const magicElixirScores = validStocks
            .map(stock => stock.magicElixirScore)
            .filter(score => !isNaN(score));
  
          const canslim75 = calculatePercentile(canslimScores, 75);
          const magicElixir75 = calculatePercentile(magicElixirScores, 75);
          const canslim95 = calculatePercentile(canslimScores, 95);
          const magicElixir95 = calculatePercentile(magicElixirScores, 95);
  
          // Calculate counts
          const stocks75thCount = validStocks.filter(stock => 
            stock.canslimScore >= canslim75 &&
            stock.magicElixirScore >= magicElixir75
          ).length;
  
          const eliteCount = validStocks.filter(stock => 
            stock.canslimScore >= canslim95 &&
            stock.magicElixirScore >= magicElixir95
          ).length;
  
          setThresholds({
            canslim75,
            magicElixir75,
            canslim95,
            magicElixir95,
            stocks75thCount,
            eliteCount
          });
  
          // Filter top performers
          const topPerformers = validStocks.filter(stock => 
            stock.canslimScore >= canslim75 &&
            stock.magicElixirScore >= magicElixir75
          );
  
          setStocks(topPerformers);
          
        } catch (error) {
          console.error('Error fetching Diamond stocks:', error);
          setError('Failed to load Diamond stocks: ' + error.message);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchDiamondStocks();
    }, []);
    
  // Sort the stocks by Diamond Score before passing to DataTable
const sortedStocks = useMemo(() => {
  return [...stocks].sort((a, b) => b.diamondScore - a.diamondScore);
}, [stocks]);


// Update the filtered stocks to use the pre-sorted array
const filteredStocks = useMemo(() => {
  if (!searchTerm) return sortedStocks;
  return sortedStocks.filter(stock => 
    stock.symbol.includes(searchTerm.toUpperCase())
  );
}, [sortedStocks, searchTerm]);



  // Debounce the search handler
  const debouncedSearch = useCallback(
    debounce((term) => {
      const upperTerm = term.toUpperCase();
      setSearchTerm(upperTerm);
      setHighlightedSymbol(
        term ? (stocks.some(stock => stock.symbol === upperTerm) ? upperTerm : null) : null
      );
    }, 300),
    [stocks]
  );

  // Handle search input
  const handleSearch = (event) => {
    const term = event.target.value;
    event.target.value = term.toUpperCase();
    debouncedSearch(term);
  };

  // Memoize the columns configuration
  const columns = useMemo(() => [
    {
      name: 'Symbol',
      selector: row => row.symbol,
      sortable: true,
      cell: row => (
        <Link 
          to={`/stock/${row.symbol}`}
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          {row.symbol}
        </Link>
      ),
    },
    {
      name: 'Diamond Score',
      selector: row => row.diamondScore,
      sortable: true,
      format: row => row.diamondScore.toFixed(2),
      sortFunction: (a, b) => b.diamondScore - a.diamondScore,
      sortField: 'diamondScore'
    },
    {
      name: 'CANSLIM Score',
      selector: row => row.canslimScore,
      sortable: true,
      format: row => row.canslimScore.toFixed(2),
    },
    {
      name: 'Magic Elixir Score',
      selector: row => row.magicElixirScore,
      sortable: true,
      format: row => row.magicElixirScore.toFixed(2),
    },
    {
      name: 'Liquidity Rating',
      selector: row => row.liquidityRating,
      sortable: true,
      format: row => row.liquidityRating.toFixed(1),
    },
    {
      name: 'Price',
      selector: row => row.currentPrice,
      sortable: true,
      format: row => `$${row.currentPrice.toFixed(2)}`,
    },
    {
      name: 'Market Cap',
      selector: row => row.marketCap,
      sortable: true,
      format: row => formatMarketCap(row.marketCap),
    },
  ], []);

  // Memoize the CustomTooltip component
// Update the CustomTooltip component
const CustomTooltip = useMemo(() => {
  return ({ active, payload }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const data = payload[0].payload;
      const isElite = data.canslimScore >= thresholds.canslim95 && 
                     data.magicElixirScore >= thresholds.magicElixir95;

      // Helper function to safely format numbers
      const formatNumber = (value, decimals = 2) => {
        const num = Number(value);
        return isNaN(num) ? '0.00' : num.toFixed(decimals);
      };

      return (
        <div className="custom-tooltip bg-white p-4 border border-gray-200 rounded-lg shadow-lg">
          <p className="symbol font-bold text-lg mb-2">
            {data.symbol}
            {isElite && (
              <span className="ml-2" style={{ color: '#00ff00' }}>★ Elite</span>
            )}
          </p>
          <p>
            <strong>Price:</strong> ${formatNumber(data.currentPrice)}
            {Number(data.currentPrice) < 15 && (
              <span className="text-red-500 ml-2">⚠️ Low Price Stock</span>
            )}
          </p>
          <p><strong>Price Performance:</strong> {formatNumber(data.rsRating)}</p>
          <p><strong>Earnings Growth:</strong> {formatNumber(data.earningsGrowth)}%</p>
          <p><strong>Sales Growth:</strong> {formatNumber(data.salesGrowth)}%</p>
          <p><strong>Liquidity Rating:</strong> {formatNumber(data.liquidityRating, 1)}</p>
          <p><strong>Market Cap:</strong> {formatMarketCap(data.marketCap)}</p>
          
          {/* Diamond Score Highlight Section */}
          <div style={{ 
            backgroundColor: '#e5e7eb',
            padding: '8px 12px',
            borderRadius: '4px',
            marginTop: '8px',
            display: 'grid',
            gap: '4px'
          }}>
            <p>
              <strong>Diamond Score:</strong> {formatNumber(data.diamondScore)}
            </p>
            <p>
              <strong>CANSLIM Score:</strong> {formatNumber(data.canslimScore)}
            </p>
            <p>
              <strong>Magic Elixir Score:</strong> {formatNumber(data.magicElixirScore)}
            </p>
          </div>

          {Number(data.currentPrice) < 15 && (
            <p className="text-red-500 mt-2 text-sm">
              Magic Elixir Score penalized due to low share price
            </p>
          )}
        </div>
      );
    }
    return null;
  };
}, [thresholds]);

// Memoize export functions
  const exportToCSV = useCallback(() => {
    const csvContent = "data:text/csv;charset=utf-8," 
      + columns.map(col => col.name).join(",") + "\n"
      + filteredStocks.map(row => columns.map(col => row[col.selector(row)]).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "diamond_stocks.csv");
    document.body.appendChild(link);
    link.click();
  }, [filteredStocks, columns]);

  const exportToXLSX = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(filteredStocks);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Diamond Stocks");
    XLSX.writeFile(workbook, "diamond_stocks.xlsx");
  }, [filteredStocks]);

  const exportToTXT = useCallback(() => {
    const txtContent = filteredStocks.map(stock => 
      `${stock.symbol}: CANSLIM Score: ${stock.canslimScore.toFixed(2)}, Magic Elixir Score: ${stock.magicElixirScore.toFixed(2)}, Liquidity Rating: ${stock.liquidityRating.toFixed(1)}, Price: $${stock.currentPrice.toFixed(2)}, Market Cap: ${formatMarketCap(stock.marketCap)}`
    ).join('\n');
    const blob = new Blob([txtContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'diamond_stocks.txt';
    link.href = url;
    link.click();
  }, [filteredStocks]);

  if (isLoading) return <div>Loading Diamond stocks...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="w-full p-4">
      <h2 className="text-2xl font-semibold mb-4">Diamond Stocks</h2>
      <div className="prose max-w-none mb-8">
        <p className="text-gray-700 leading-relaxed">
          On this page, only stocks in the top 25% of both CANSLIM and Magic Elixir scores are shown. 
          This means a CANSLIM score greater than <span className="font-semibold text-blue-600">
          {thresholds.canslim75?.toFixed(2)}</span> and a Magic Elixir score greater than <span 
          className="font-semibold text-blue-600">{thresholds.magicElixir75?.toFixed(2)}</span>. 
          There are <span className="font-semibold text-blue-600">{thresholds.stocks75thCount}</span> stocks 
          that meet that criteria.
        </p>
        <p className="text-gray-700 leading-relaxed mt-4">
          If we narrow the threshold to 95th percentile in both metrics, only <span 
          className="font-semibold text-green-600">{thresholds.eliteCount}</span> stocks meet that 
          criteria, which is a CANSLIM Score greater than <span className="font-semibold text-green-600">
          {thresholds.canslim95?.toFixed(2)}</span> and a Magic Elixir score greater than <span 
          className="font-semibold text-green-600">{thresholds.magicElixir95?.toFixed(2)}</span>. 
          These are the diamond stocks, and they fall within the green section of the plot below.
        </p>
      </div>
          {/* Search Bar */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by symbol..."
              onChange={handleSearch}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            {searchTerm && !highlightedSymbol && (
              <p className="text-red-500 text-sm mt-1">Symbol not found</p>
            )}
          </div>

          <div style={{ width: '100%', height: 600 }}>
            <ResponsiveContainer>
              <ScatterChart margin={{ top: 20, right: 20, bottom: 60, left: 60 }}>
     <XAxis 
        type="number" 
        dataKey="canslimScore" 
        name="CANSLIM Score" 
        domain={[getAxisDomains.min, 6]}
        label={{ value: 'CANSLIM Score', position: 'bottom', offset: 0 }}
        ticks={getCanslimTicks}
        tickFormatter={formatCanslimTick}
        tick={{ fontSize: 12 }}
        tickLine={{ stroke: '#666' }}
        axisLine={{ stroke: '#666' }}
      />
      <YAxis 
        type="number" 
        dataKey="magicElixirScore" 
        name="Magic Elixir Score"
        domain={[thresholds.magicElixir75, 100]}
        label={{ value: 'Magic Elixir Score', angle: -90, position: 'insideLeft', offset: -10 }}
        ticks={getMagicElixirTicks}
        tickFormatter={formatMagicElixirTick}
        tick={{ fontSize: 12 }}
        tickLine={{ stroke: '#666' }}
        axisLine={{ stroke: '#666' }}
      />
                <ZAxis 
                  type="number" 
                  dataKey="liquidityRating"
                  range={[5, 150]} 
                  name="Liquidity Rating"
                />
                {/* 90th percentile reference lines */}
                <ReferenceLine 
                  x={thresholds.canslim95} 
                  stroke="#ff0000" 
                  strokeDasharray="3 3"
                  label={{ 
                    value: '95th percentile', 
                    position: 'top',
                    fill: '#666',
                    fontSize: 12 
                  }}
                />
                <ReferenceLine 
                  y={thresholds.magicElixir95} 
                  stroke="#ff0000" 
                  strokeDasharray="3 3"
                  label={{ 
                    value: '95th percentile', 
                    angle: -90,
                    position: 'right',
                    fill: '#666',
                    fontSize: 12 
                  }}
                />
                {/* Elite quadrant shading */}
                <ReferenceArea 
                  x1={thresholds.canslim95} 
                  x2={6}
                  y1={thresholds.magicElixir95} 
                  y2={100}
                  fill="#00ff00"
                  fillOpacity={0.1}
                  label={{
                    value: 'Elite Performers',
                    position: 'center',
                    fill: '#666',
                    fontSize: 14
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Scatter 
  data={stocks} 
  onClick={(data) => handlePointClick(data)}
  onMouseEnter={(props) => {
    if (props && props.payload) {
      setHoveredPoint(stocks.findIndex(s => s.symbol === props.payload.symbol));
    }
  }}
  onMouseLeave={() => setHoveredPoint(null)}
  cursor="pointer"
>
  {stocks.map((entry, index) => {
    const isElite = entry.canslimScore >= thresholds.canslim95 && 
                    entry.magicElixirScore >= thresholds.magicElixir95;
    const isHovered = hoveredPoint === index;
    
    return (
      <Cell 
        key={`cell-${index}`} 
        fill={highlightedSymbol 
          ? (entry.symbol === highlightedSymbol ? COLORS[index % COLORS.length] : '#cccccc')
          : (isElite ? '#00ff00' : COLORS[index % COLORS.length])
        }
        opacity={highlightedSymbol 
          ? (entry.symbol === highlightedSymbol ? 1 : 0.3)
          : (isElite ? 1 : 0.8)
        }
        r={isHovered ? 8 : 5}  // Size of the dot
      />
    );
  })}
</Scatter>
              </ScatterChart>
            </ResponsiveContainer>
          </div>

          <div className="mt-8">  
  <div className="flex justify-between items-center mb-4">
  <h3 className="text-xl font-semibold">Diamond Stocks Table</h3>
  <ExportDropdown 
    onExportCSV={exportToCSV}
    onExportXLSX={exportToXLSX}
    onExportTXT={exportToTXT}
  />
</div>

  <DataTable
    columns={columns}
    data={filteredStocks}
    pagination
    highlightOnHover
    responsive
    defaultSortFieldId="diamondScore"
    defaultSortAsc={false}
    sortFunction={(rows, selector, direction) => {
      return [...rows].sort((a, b) => {
        const aField = selector(a);
        const bField = selector(b);
        let comparison = 0;
        
        if (bField > aField) comparison = 1;
        if (bField < aField) comparison = -1;
        
        return direction === 'asc' ? comparison * -1 : comparison;
      });
    }}
  />
</div>         
    </div>
  );
};

export default DiamondStocks;