import React from 'react';
import { 
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea
} from 'recharts';

const DiamondStocksPlot = ({ symbol, stockData, thresholds = {}, rankings }) => {
  // Parse thresholds as numbers with defaults
  const plotThresholds = {
    canslim95: stockData.thresholds?.canslim95 || 4.2,
    magicElixir95: stockData.thresholds?.magicElixir95 || 85,
  };

  // Use server-calculated values
  const focusPoint = [{
    symbol,
    canslimScore: Number(stockData?.canslimScore) || 0,
    magicElixirScore: Number(stockData?.magicElixirScore) || 0,
    currentPrice: Number(stockData?.currentPrice) || 0,
    rsRating: Number(stockData?.rsRating) || 0,
    earningsGrowth: Number(stockData?.earningsGrowth) || 0,
    salesGrowth: Number(stockData?.salesGrowth) || 0,
    liquidityRating: Number(stockData?.liquidityRating) || 0,
    diamondScore: Number(stockData?.diamondScore) || 0
  }];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const data = payload[0].payload;
      const isElite = 
        data.canslimScore >= plotThresholds.canslim95 && 
        data.magicElixirScore >= plotThresholds.magicElixir95;

      return (
        <div className="custom-tooltip">
          <div className="bg-white p-4 border border-gray-200 rounded-lg shadow-lg max-w-xs">
            <p className="symbol font-bold text-lg mb-2">
              {data.symbol}
              {isElite && (
                <span className="ml-2 text-green-500">★ Elite</span>
              )}
            </p>
            
            <p>
              <strong>Price:</strong> ${(data.currentPrice || 0).toFixed(2)}
              {data.currentPrice < 15 && (
                <span className="text-red-500 ml-2">⚠️ Low Price Stock</span>
              )}
            </p>
            <p><strong>Price Performance:</strong> {data.rsRating.toFixed(0)}</p>
            <p><strong>Earnings Growth:</strong> {data.earningsGrowth.toFixed(0)}%</p>
            <p><strong>Sales Growth:</strong> {data.salesGrowth.toFixed(0)}%</p>
            <p><strong>Liquidity Rating:</strong> {data.liquidityRating.toFixed(1)}</p>

            <div style={{ 
              backgroundColor: '#e5e7eb',
              padding: '8px 12px',
              borderRadius: '4px',
              marginTop: '8px',
              display: 'grid',
              gap: '4px'
            }}>
              <p>
                <strong>Diamond Score:</strong> {rankings.diamond.score.toFixed(2)}
              </p>
              <p>
                <strong>CANSLIM Score:</strong> {rankings.canslim.score.toFixed(2)}
              </p>
              <p>
                <strong>Magic Elixir Score:</strong> {rankings.magicElixir.score.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mt-8">
      <h3 className="text-xl font-semibold mb-4">Diamond Stocks Analysis</h3>
      
      {/* Interpretation guide */}
      <div className="text-sm text-gray-600 mb-4">
        <p>The Diamond Metric is how we locate stocks that have elite compatability with both the CANSLIM criteria as laid out in How to Make Money in Stocks by William J. O'Neil, and with the additional anecdotal factors that Jim Roppel provided from his relationship with Bill O'Neil. We want to ensure that we are tracking the true elite of the elite, and so only stocks with a CANSLIM Score and a Magic Elixir Score in the 95th percentile or above fall into the Diamond Zone.</p>
        <p className="text-sm text-gray-600">
          {`${symbol}'s Diamond score is ${rankings.diamond.score.toFixed(2)}, which is ranked ${rankings.diamond.rank} out of all ${rankings.diamond.totalStocks} stocks, putting it in the ${rankings.diamond.percentile.toFixed(1)}th percentile of all stocks.`}
        </p>       
      </div>

      <div style={{ width: '100%', height: 400 }} className="border border-gray-300">
        <ResponsiveContainer>
          <ScatterChart margin={{ top: 20, right: 20, bottom: 60, left: 60 }}>
            <defs>
              <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#00ff00" stopOpacity={0.1}/>
                <stop offset="100%" stopColor="#00ff00" stopOpacity={0.1}/>
              </linearGradient>
            </defs>
            
            <XAxis 
              type="number" 
              dataKey="canslimScore" 
              name="CANSLIM Score" 
              domain={[0, 6]}
              label={{ 
                value: 'CANSLIM Score', 
                position: 'bottom', 
                offset: 0 
              }}
            />
            <YAxis 
              type="number" 
              dataKey="magicElixirScore" 
              name="Magic Elixir Score"
              domain={[0, 100]}
              label={{ 
                value: 'Magic Elixir Score', 
                angle: -90, 
                position: 'insideLeft' 
              }}
            />

            {/* 95th percentile reference lines */}
            <ReferenceLine 
              x={plotThresholds.canslim95} 
              stroke="green" 
              strokeDasharray="3 3"
              label={{ 
                value: '95th percentile', 
                position: 'top',
                fill: '#666',
                fontSize: 12 
              }}
            />
            
            <ReferenceLine 
              y={plotThresholds.magicElixir95} 
              stroke="green" 
              strokeDasharray="3 3"
              label={{ 
                value: '95th percentile', 
                position: 'right',
                fill: '#666',
                fontSize: 12 
              }}
            />

            {/* Elite zone - always visible green area */}
            <ReferenceArea 
              x1={plotThresholds.canslim95} 
              x2={6}
              y1={plotThresholds.magicElixir95} 
              y2={100}
              fill="url(#greenGradient)"
              fillOpacity={1}
              label={{
                value: 'Diamond Zone',
                position: 'center',
                fill: '#666',
                fontSize: 14
              }}
            />

            {/* Stock point */}
            <Scatter 
              data={focusPoint} 
              fill="#2563eb"
              r={6}
            />
            
            <Tooltip content={<CustomTooltip />} />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DiamondStocksPlot;