import React, { useState } from 'react';
import { useAuth } from './contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, resetPassword } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setMessage('');
      setLoading(true);
      await login(email, password);
      navigate('/canslim-stocks', { replace: true });
    } catch (err) {
      console.error('Login error:', err);
      console.log('Error code:', err.code);
      
      if (err.code === 'auth/invalid-credential') {
        setError('Invalid password');
      } else {
        setError('Failed to sign in: ' + (err.message || 'Please check your credentials'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    try {
      setError('');
      setMessage('');
      setLoading(true);
      await resetPassword(email);
      setMessage('Check your email inbox for password reset instructions');
    } catch (err) {
      setError('Failed to reset password. Please check your email address.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <h2>Welcome Back</h2>
          <p>Access your Growth Stock Analyzer account</p>
        </div>

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              id="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button 
              type="button"
              onClick={handleResetPassword}
              className="text-sm text-blue-600 hover:text-blue-800 mt-2 block"
              disabled={loading}
            >
              Forgot password?
            </button>
          </div>

          {error && (
            <div className="error-message">{error}</div>
          )}

          {message && (
            <div className="success-message">{message}</div>
          )}

          <button 
            type="submit" 
            className="login-button"
            disabled={loading}
          >
            {loading ? 'Signing in...' : 'Sign in'}
          </button>

          <Link to="/subscription-plans" className="trial-button">
            Start Your 14-Day Free Trial
          </Link>
        </form>
      </div>

      <div className="disclaimer-section">
        <p className="disclaimer-title">IMPORTANT DISCLAIMER</p>
        <p className="disclaimer-text">
          The information provided on this website is for educational and informational purposes only.
        </p>
        <p className="disclaimer-text">
          This tool does not constitute financial advice, investment advice, trading advice, or any other sort of advice. 
          The content is presented "as-is" without any representations or warranties.
        </p>
        <p className="disclaimer-text">
          Past performance is not indicative of future results. Always conduct your own research and consult with a licensed financial advisor.
        </p>
        <p className="copyright-text">
          © 2024 Growth Stock Analyzer. All analysis techniques based on publicly available CANSLIM methodology.
        </p>
      </div>
    </div>
  );
}