import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { CheckIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import ExportDropdown from './ExportDropdown';
import { FormControlLabel, Switch } from '@mui/material';
import { 
  FormControl, 
  Select, 
  MenuItem, 
  Checkbox, 
  ListItemText, 
  OutlinedInput 
} from '@mui/material';
import D3RadarChart from './D3RadarChart';

// Style object to add extra padding around the radar chart
const chartContainerStyle = {
  padding: '20px 0', // Adds 20px padding above and below the chart
};


const calculateRankings = (stocks) => {
  // Sort stocks by CANSLIM score in descending order
  const sortedStocks = [...stocks].sort((a, b) => b.canslimScore - a.canslimScore);
  
  // Create a map of symbol to rank
  const rankings = new Map();
  sortedStocks.forEach((stock, index) => {
    rankings.set(stock.symbol, index + 1);
  });
  
  return rankings;
};

const StockList = () => {
  const [stocks, setStocks] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [marketCapFilters, setMarketCapFilters] = useState('all');
  const [displayCount, setDisplayCount] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [viewMode, setViewMode] = useState('chart'); // Default to 'chart'
  const [rankings, setRankings] = useState(new Map());
  const marketCapOptions = ['All', 'Nano', 'Micro', 'Small', 'Medium', 'Large', 'Mega'];

  // Ref for observer to lazy load stocks
  const observer = useRef();

  useEffect(() => {
    console.log("View mode changed to:", viewMode);
  }, [viewMode]);

  const lastStockElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Fetching more stocks...");
          setDisplayCount((prevCount) => prevCount + 20);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getStocks`);  
        console.log('Fetched stocks data:', response.data);
        if (!response.data || response.data.length === 0) {
          throw new Error('No data received from backend');
        }
  
        const stocksData = response.data;
        const rankingsMap = calculateRankings(stocksData);
        
        setRankings(rankingsMap);
        setStocks(stocksData);
        setFilteredStocks(stocksData.slice(0, displayCount));
      } catch (error) {
        console.error('Error fetching stocks:', error);
        setError(`Failed to load stocks: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchStocks();
  }, []);

  // Update filtered stocks when filters or display count changes
  useEffect(() => {
    const filtered = marketCapFilters.includes('all')
      ? stocks
      : stocks.filter((stock) =>
          stock.CapType &&
          marketCapFilters.some((filter) =>
            stock.CapType.toLowerCase().includes(filter)
          )
        );
    setFilteredStocks(filtered.slice(0, displayCount));
    setHasMore(filtered.length > displayCount);
  }, [stocks, marketCapFilters, displayCount]);

  const handleMarketCapChange = (value) => {
    setMarketCapFilters((prev) => {
      const currentFilters = Array.isArray(prev) ? prev : ['all'];

      if (value === 'all') {
        return ['all'];
      }

      const newFilters = currentFilters.filter((f) => f !== 'all');

      if (newFilters.includes(value)) {
        return newFilters.filter((f) => f !== value);
      } else {
        return [...newFilters, value];
      }
    });
  };

  // Format market cap function
  const formatMarketCap = (marketCap) => {
    if (!marketCap) return '$0.00M';
    if (marketCap >= 1e6) return `$${(marketCap / 1e6).toFixed(2)}T`;  // Trillion
    if (marketCap >= 1e3) return `$${(marketCap / 1e3).toFixed(2)}B`;  // Billion
    return `$${marketCap.toFixed(2)}M`;  // Million
  };

  // Export functionalities
  const exportToCSV = () => {
    console.log("Exporting to CSV...");
    const csvContent =
      "data:text/csv;charset=utf-8," +
      columns.map((col) => col.name).join(",") +
      "\n" +
      filteredStocks
        .map((row) => columns.map((col) => col.selector(row)).join(","))
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "stock_list.csv");
    document.body.appendChild(link);
    link.click();
  };

  const exportToXLSX = () => {
    console.log("Exporting to XLSX...");
    const worksheet = XLSX.utils.json_to_sheet(
      filteredStocks.map((stock) => ({
        Symbol: stock.symbol,
        Name: stock.Name,
        C: stock.c,
        A: stock.a,
        N: stock.n,
        S: stock.s,
        L: stock.l,
        I: stock.i,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock List");
    XLSX.writeFile(workbook, "stock_list.xlsx");
  };

  const exportToTXT = () => {
    console.log("Exporting to TXT...");
    const txtContent = filteredStocks
      .map(
        (stock) =>
          `${stock.symbol}: ${stock.Name}, C: ${stock.c.toFixed(
            2
          )}, A: ${stock.a.toFixed(2)}, N: ${stock.n.toFixed(2)}, S: ${stock.s.toFixed(2)}, L: ${stock.l.toFixed(
            2
          )}, I: ${stock.i.toFixed(2)}`
      )
      .join("\n");
    const blob = new Blob([txtContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "stock_list.txt";
    link.href = url;
    link.click();
  };
            
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const descriptions = {
        C: "Current Quarterly Earnings",
        A: "Annual Earnings Growth",
        N: "New Products/Leadership",
        S: "Supply and Demand",
        L: "Leader or Laggard",
        I: "Institutional Sponsorship"
      };
  
      return (
        <div className="custom-tooltip bg-white p-4 border border-gray-200 rounded-lg shadow-lg" 
          style={{ 
            maxWidth: '300px',
            width: '100%'
          }}>
          <div style={{ 
            backgroundColor: '#e5e7eb',
            padding: '8px 12px',
            borderRadius: '4px',
            marginBottom: '8px'
          }}>
            <p className="font-bold text-lg">
              {data.category}: {data.value.toFixed(2)}
            </p>
          </div>
          <p className="text-sm text-gray-600 mb-2 font-bold">
            {descriptions[data.category]}
          </p>
          <p className="text-xs text-gray-500 italic">
            This value is a quantification of the stock's strength in this category on a scale of 1-100. 
            It is not a representation of any business metric.
          </p>
        </div>
      );
    }
    return null;
  };

  // Define the ScoreTooltip function
  const ScoreTooltip = ({ show, target, content }) => {
    if (!show || !target) return null;

    const rect = target.getBoundingClientRect();
    const tooltipWidth = 300;
    const viewportWidth = window.innerWidth;
    const leftOffset = rect.left + rect.width / 2;
    const rightSpace = viewportWidth - leftOffset;

    let left;
    if (leftOffset < tooltipWidth / 2) {
      left = 10;
    } else if (rightSpace < tooltipWidth / 2) {
      left = viewportWidth - tooltipWidth - 10;
    } else {
      left = leftOffset - tooltipWidth / 2;
    }

    const tooltipStyle = {
      position: 'absolute',
      left: `${left}px`,
      top: `${rect.bottom + window.scrollY + 5}px`,
      width: `${tooltipWidth}px`,
      zIndex: 1000,
      backgroundColor: '#1a202c',
      color: 'white',
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: '1px solid #2d3748',
      fontSize: '14px',
    };

    return ReactDOM.createPortal(
      <div style={tooltipStyle}>
        {content}
      </div>,
      document.body
    );
  };

  const columns = [
    {
      name: "Rank",
      selector: (row) => rankings.get(row.symbol),
      sortable: true,
      width: '80px',
      cell: (row) => (
        <div className="font-medium text-gray-900">
          {rankings.get(row.symbol)}
        </div>
      ),
    },
    {
      name: "Symbol",
      selector: (row) => row.symbol,
      sortable: true,
      cell: row => (
        <Link 
          to={`/stock/${row.symbol}`}
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          {row.symbol}
        </Link>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "CANSLIM Score",
      selector: (row) => row.canslimScore,
      sortable: true,
      width: '150px',
      format: (row) => row.canslimScore.toFixed(2),
      cell: (row) => (
        <div className="font-medium">
          {row.canslimScore.toFixed(2)}
        </div>
      ),
    },
    {
      name: "C",
      selector: (row) => row.c,
      sortable: true,
      format: (row) => row.c.toFixed(2),
    },

    {
      name: "A",
      selector: (row) => row.a,
      sortable: true,
      format: (row) => row.a.toFixed(2),
    },
    {
      name: "N",
      selector: (row) => row.n,
      sortable: true,
      format: (row) => row.n.toFixed(2),
    },
    {
      name: "S",
      selector: (row) => row.s,
      sortable: true,
      format: (row) => row.s.toFixed(2),
    },
    {
      name: "L",
      selector: (row) => row.l,
      sortable: true,
      format: (row) => row.l.toFixed(2),
    },
    {
      name: "I",
      selector: (row) => row.i,
      sortable: true,
      format: (row) => row.i.toFixed(2),
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="mb-4"></div>

      <div className="mb-4 flex justify-between items-center">
      <FormControl sx={{ width: 200, marginRight: 2 }}>
  <Select
    multiple
    displayEmpty
    value={Array.isArray(marketCapFilters) ? marketCapFilters : ['all']}
    onChange={(event) => {
      const value = Array.isArray(event.target.value) ? event.target.value : [];
      // If "All" is selected, clear other selections
      if (value.includes('all') && !marketCapFilters.includes('all')) {
        setMarketCapFilters(['all']);
      }
      // If another option is selected while "All" is selected, remove "All"
      else if (!value.includes('all') && marketCapFilters.includes('all')) {
        setMarketCapFilters(value.filter(v => v !== 'all'));
      }
      // If no options are selected, default to "All"
      else if (value.length === 0) {
        setMarketCapFilters(['all']);
      }
      // Normal multiple selection
      else {
        setMarketCapFilters(value);
      }
    }}
    input={<OutlinedInput />}
    renderValue={(selected) => {
      if (!Array.isArray(selected)) return 'All Market Caps';
      if (selected.includes('all')) return 'All Market Caps';
      if (selected.length === 0) return 'Select Market Cap';
      return selected.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(', ');
    }}
    sx={{
      '& .MuiSelect-select': {
        padding: '8px 14px',
      },
    }}
  >
    {['All', 'Nano', 'Micro', 'Small', 'Medium', 'Large', 'Mega'].map((option) => (
      <MenuItem 
        key={option.toLowerCase()} 
        value={option.toLowerCase()}
        sx={{ padding: '0 14px' }}
      >
        <Checkbox 
          checked={Array.isArray(marketCapFilters) && marketCapFilters.includes(option.toLowerCase())}
          color="primary"
        />
        <ListItemText primary={option} />
      </MenuItem>
    ))}
  </Select>
</FormControl>
        <FormControlLabel
      control={
        <Switch
          checked={viewMode === "table"}
          onChange={() => setViewMode(viewMode === "table" ? "chart" : "table")}
          color="primary"
        />
      }
      label={viewMode === "table" ? "Table View" : "Chart View"}
      className="ml-4"  // Add margin to the left
      sx={{ marginLeft: '16px' }}  // Alternative way using Material UI's sx prop
    />
 </div>

      {viewMode === "chart" ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {filteredStocks.map((stock, index) => (
            <div
              key={stock.symbol}
              ref={index === filteredStocks.length - 1 ? lastStockElementRef : null}
              className="border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-200"
            >
              <Link to={`/stock/${stock.symbol}`} className="text-blue-500 hover:text-blue-700">
                <h2 className="text-xl font-bold mb-2">
                  <span className="mr-2 text-gray-500">#{index + 1}. </span>
                  {stock.symbol} - {stock.Name || "N/A"}
                </h2>
              </Link>
              <p className="text-sm text-gray-600 mb-1">
  <span className="stock-label font-bold">Market Cap Type:</span> {stock.CapType || "N/A"}
</p>
<p className="text-sm text-gray-600 mb-2">
  <span className="stock-label font-bold">Market Cap:</span> {formatMarketCap(stock.MarketCapMil)}
</p>
<p>
  <span
    onMouseEnter={(e) =>
      setActiveTooltip({
        target: e.target,
        content:
          "The CANSLIM Score is a proprietary combined quantification of a stock's strength in all the different components of analysis deemed important in the strategy popularized by William J. O'Neil.",
      })
    }
    onMouseLeave={() => setActiveTooltip(null)}
    className="stock-label cursor-help border-b border-dotted border-gray-500 font-bold"
  >
    CANSLIM Score:
  </span>{" "}
  {stock.canslimScore !== undefined
    ? stock.canslimScore.toFixed(2)
    : "N/A"}
</p>
<div style={chartContainerStyle}>
<D3RadarChart
  data={[
    { category: "C", value: stock.c },
    { category: "A", value: stock.a },
    { category: "N", value: stock.n },
    { category: "S", value: stock.s },
    { category: "L", value: stock.l },
    { category: "I", value: stock.i }
  ]}
  width={300}
  height={300}
/>
</div>
            </div>
          ))}
        </div>
      ) : (
<div className="relative">
  <div className="flex justify-between items-center mb-4">
    <h3 className="text-xl font-semibold">CANSLIM Stocks Table</h3>
    <ExportDropdown 
      onExportCSV={exportToCSV}
      onExportXLSX={exportToXLSX}
      onExportTXT={exportToTXT}
    />
  </div>
  <div className="flex flex-col">
    <DataTable
      columns={columns}
      data={filteredStocks}
      pagination
      highlightOnHover
      responsive
    />
  </div>
</div>
)}

      {activeTooltip && (
        <ScoreTooltip show={true} target={activeTooltip.target} content={activeTooltip.content} />
      )}

      {isLoading && <div className="text-center mt-4">Loading more stocks...</div>}
    </div>
  );
};

export default StockList;

