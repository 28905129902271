import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { Alert, AlertDescription } from './AlertComponent';
import { Card, CardHeader, CardTitle, CardContent } from './CardComponent';

// Define Firebase Functions URL - replace with your actual project ID
const FIREBASE_FUNCTIONS_BASE = 'https://us-central1-stock-analyzer-afd6d.cloudfunctions.net';

const SubscriptionSuccess = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [customerEmail, setCustomerEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { signup } = useAuth();

  useEffect(() => {
    async function getSessionDetails() {
      try {
        console.log('Fetching session details for:', sessionId);
        const response = await fetch(`${FIREBASE_FUNCTIONS_BASE}/getCheckoutSession`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Session verification failed:', errorData);
          throw new Error('Failed to verify session');
        }

        const data = await response.json();
        console.log('Session details received:', data);
        setCustomerEmail(data.customerEmail);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching session:', err);
        setError('Unable to verify your subscription. Please contact support.');
        setLoading(false);
      }
    }

    if (sessionId) {
      getSessionDetails();
    } else {
      console.error('No session ID provided');
      setError('Invalid session. Please try subscribing again.');
      setLoading(false);
    }
  }, [sessionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    if (password.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }
  
    try {
      setLoading(true);
      console.log('Starting account creation for:', customerEmail);
      
      // First create the user account
      const userCredential = await signup(customerEmail, password);
      console.log('User created successfully:', userCredential?.user?.uid);
  
      // Then link the subscription
      console.log('Linking subscription for:', customerEmail);
      const response = await fetch(`${FIREBASE_FUNCTIONS_BASE}/linkSubscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email: customerEmail,
          sessionId: sessionId  // Adding sessionId to the request
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Subscription linking failed:', errorData);
        throw new Error(errorData.error || 'Failed to link subscription');
      }

      const data = await response.json();
      console.log('Subscription linked successfully:', data);
  
      // Navigate to the dashboard
      navigate('/canslim-stocks', { replace: true });
      
    } catch (err) {
      console.error('Signup Error:', err);
      if (err.code === 'auth/email-already-in-use') {
        setError(
          'An account with this email already exists. Please sign in with your existing account to access your subscription.'
        );
      } else {
        setError(
          `Error: ${err.message || 'There was an error creating your account. Please try again or contact support.'}`
        );
      }
      setLoading(false);
    }
  };
    
  if (error) {
    return (
      <div className="subscription-container">
        <div className="subscription-card" style={{ maxWidth: '32rem' }}>
          <div className="subscription-header">
            <h2>Error</h2>
          </div>
          
          <div className="error-message">
            {error}
          </div>
          
          {error.includes('already exists') ? (
            <div className="login-form" style={{ padding: '1rem' }}>
              <p className="text-sm text-gray-600 mb-4">
                You can either:
              </p>
              <button
                className="subscription-button"
                onClick={() => navigate('/login')}
              >
                Sign in with existing account
              </button>
              <div style={{ height: '1rem' }}></div>
              <button
                className="subscription-button"
                onClick={() => navigate('/support')}
              >
                Contact Support
              </button>
            </div>
          ) : (
            <div style={{ padding: '1rem' }}>
              <button
                className="subscription-button"
                onClick={() => navigate('/subscription-plans')}
              >
                Return to Subscription Plans
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="subscription-container">
        <div className="subscription-card" style={{ maxWidth: '32rem' }}>
          <div className="subscription-header">
            <h2>Setting Up Your Account</h2>
            <p>Just a moment while we prepare everything...</p>
          </div>
          <div className="text-center mt-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="subscription-container">
      <div className="subscription-card" style={{ maxWidth: '32rem' }}>
        <div className="subscription-header">
          <h2>Complete Your Account Setup</h2>
          <p>You're almost ready to start analyzing stocks!</p>
        </div>
        
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={customerEmail}
              disabled
              className="bg-gray-50"
            />
            <p className="text-sm text-gray-500">
              This is the email you used for payment
            </p>
          </div>

          <div className="form-group">
            <label>Create Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength={6}
              placeholder="Choose a secure password"
            />
          </div>

          <div className="form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              minLength={6}
              placeholder="Confirm your password"
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="subscription-button"
          >
            {loading ? 'Creating Account...' : 'Complete Setup & Start Using Growth Stock Analyzer'}
          </button>
        </form>

        <div className="trial-details">
          <p>Your 14-day trial starts after account creation</p>
          <p>You can explore all features risk-free during your trial period</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;