import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { User, LogOut, Settings, Send } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./DropdownMenu";
import StockList from './StockList';
import StockDetail from './StockDetail';
import MagicElixirStocks from './MagicElixirStocks';
import DiamondStocks from './DiamondStocks';
import StockSearch from './StockSearch';
import Login from './Login';
import './App.css';
import SignupWithSubscription from './SignupWithSubscription';
import SubscriptionPlans from './SubscriptionPlans';
import SubscriptionSuccess from './SubscriptionSuccess';
import AccountPage from './AccountPage';
import OrientationHandler from './OrientationHandler';
import ContactForm from './ContactForm';

// Account Navigation Component
const AccountNav = ({ email, onLogout }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="nav-icon-button ml-4">
          <User size={20} className="text-white" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="dropdown-menu-content" align="end">
        <div className="px-3 py-2">
          <h3 className="text-sm font-semibold text-gray-700">Account</h3>
          <p className="text-sm text-gray-500 truncate">{email}</p>
        </div>
        <DropdownMenuSeparator className="dropdown-menu-separator" />
        <DropdownMenuGroup>
          <Link to="/account">
            <DropdownMenuItem className="dropdown-menu-item">
              <Settings className="mr-2 h-4 w-4 text-gray-500" />
              <span>Account Settings</span>
            </DropdownMenuItem>
          </Link>
          <Link to="/contact">
            <DropdownMenuItem className="dropdown-menu-item">
              <Send className="mr-2 h-4 w-4 text-gray-500" />
              <span>Contact Support</span>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator className="dropdown-menu-separator" />
          <DropdownMenuItem onClick={onLogout} className="dropdown-menu-item dropdown-menu-signout">
            <LogOut className="mr-2 h-4 w-4" />
            <span>Sign out</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

// PrivateRoute component
const PrivateRoute = ({ children, redirectPath = "/login" }) => {
  const { user, loading } = useAuth();
  console.log("PrivateRoute - user:", user, "loading:", loading);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to={redirectPath} replace />;
};

// Navbar Component
const NavBar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <nav className="topnav">
      <div className="nav-content">
        <ul className="flex items-center w-full">
          {user && (
            <>
              <li>
                <Link to="/canslim-stocks" className="hover:text-blue-100 transition-colors">
                  CANSLIM Stocks
                </Link>
              </li>
              <li>
                <Link to="/magic-elixir" className="hover:text-blue-100 transition-colors">
                  Magic Elixir
                </Link>
              </li>
              <li>
                <Link to="/diamond-stocks" className="hover:text-blue-100 transition-colors">
                  Diamond Stocks
                </Link>
              </li>
              <li className="ml-auto">
                <StockSearch />
              </li>
              <li>
                <AccountNav email={user.email} onLogout={handleLogout} />
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

// App Component
const App = () => {
  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen">
          <NavBar />
          <main className="flex-grow main-content">
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/subscription-plans" element={<SubscriptionPlans />} />
              <Route path="/subscription-success" element={<SubscriptionSuccess />} />
              <Route path="/" element={<Login />} />

              {/* Protected Routes */}
              <Route path="/canslim-stocks" element={<PrivateRoute><StockList /></PrivateRoute>} />
              <Route path="/account" element={<PrivateRoute><AccountPage /></PrivateRoute>} />
              <Route path="/contact" element={<PrivateRoute><ContactForm /></PrivateRoute>} />  
              <Route path="/stock/:symbol" element={<PrivateRoute><OrientationHandler><StockDetail /></OrientationHandler></PrivateRoute>} />
              <Route path="/magic-elixir" element={<PrivateRoute><OrientationHandler><MagicElixirStocks /></OrientationHandler></PrivateRoute>} />
              <Route path="/diamond-stocks" element={<PrivateRoute><OrientationHandler><DiamondStocks /></OrientationHandler></PrivateRoute>} />
            </Routes>
          </main>

          <footer className="bg-gray-800 text-gray-300 py-6 px-4 mt-auto">
            <div className="max-w-7xl mx-auto text-center">
              <p className="text-sm mb-4">
                <strong>IMPORTANT DISCLAIMER:</strong> The information provided on this website is for educational and informational purposes only.
              </p>
              <p className="text-xs text-gray-400 mb-2">
                This tool does not constitute financial advice, investment advice, trading advice, or any other sort of advice.
              </p>
              <p className="text-xs text-gray-400">
                © {new Date().getFullYear()} Growth Stock Analyzer. All analysis techniques used are based on publicly available information about the CANSLIM methodology.
              </p>
            </div>
          </footer>
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;