import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import axios from 'axios';

const StockSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [allStocks, setAllStocks] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);  // New state for initial load
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  // Initial data fetch
  useEffect(() => {
    const fetchStocks = async () => {
      try {
        setIsInitialLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/getAllScoredStocks`);       
         setAllStocks(response.data);
      } catch (error) {
        console.error('Error fetching stocks:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchStocks();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm.length < 1) {
      setSuggestions([]);
      setShowDropdown(false);
      return;
    }

    const searchLower = searchTerm.toLowerCase();
    const filtered = allStocks
      .filter(stock => 
        stock.symbol.toLowerCase().includes(searchLower) ||
        (stock.name && stock.name.toLowerCase().includes(searchLower))
      )
      .slice(0, 8);

    setSuggestions(filtered);
    setShowDropdown(true);
  }, [searchTerm, allStocks]);

  const handleInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelect = (symbol) => {
    setSearchTerm('');
    setSuggestions([]);
    setShowDropdown(false);
    navigate(`/stock/${symbol}`);
  };

  return (
    <div 
      ref={searchRef} 
      className="search-container"
      style={{ 
        width: '170px',
        display: 'inline-block',
        position: 'relative'
      }}
    >
      <input
        type="text"
        value={searchTerm}
        onChange={handleInput}
        placeholder="Search stocks..."
        className="search-input"
        style={{
          width: '100%',
          height: '28px',
          padding: '0 30px 0 10px',
          border: '1px solid white',
          borderRadius: '4px',
          backgroundColor: 'white',
          color: 'black'
        }}
        autoComplete="off"
      />
      <Search 
        className="search-icon"
        style={{
          position: 'absolute',
          right: '-25px',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '16px',
          height: '16px',
          color: '#666',
          pointerEvents: 'none'
        }}
      />

      {showDropdown && (
        <div 
          className="search-dropdown"
          style={{ 
            position: 'fixed',
            zIndex: 9999,
            width: searchRef.current ? searchRef.current.offsetWidth : '400px',
            maxHeight: '300px',
            overflowY: 'auto',
            left: searchRef.current ? searchRef.current.getBoundingClientRect().left : 0,
            top: searchRef.current ? searchRef.current.getBoundingClientRect().bottom + 4 : 0,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          {isInitialLoading ? (
            <div style={{
              padding: '8px 12px',
              textAlign: 'center',
              color: '#666'
            }}>
              Loading...
            </div>
          ) : suggestions.length > 0 ? (
            suggestions.map((stock) => (
              <div
                key={stock.symbol}
                onClick={() => handleSelect(stock.symbol)}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #eee',
                  backgroundColor: 'white'
                }}
                className="search-suggestion"
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <span style={{ fontWeight: 500, color: '#333' }}>{stock.symbol}</span>
                  {stock.name && (
                    <>
                      <span style={{ color: '#666' }}>-</span>
                      <span style={{ color: '#666', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {stock.name}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div style={{
              padding: '8px 12px',
              textAlign: 'center',
              color: '#666'
            }}>
              No matches found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StockSearch;