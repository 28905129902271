import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './contexts/AuthContext';

// Version check
console.log('SubscriptionPlans loading - Version 2.1 - Root Firebase Config');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const plans = [
  {
    name: 'Monthly',
    price: '$14.99/month',
    priceId: 'price_1QIt4706yJLlYQYgHeU31Xb0',
    description: 'Month-to-month flexibility',
    commitment: 'Monthly billing',
    savings: 'Base rate'
  },
  {
    name: 'Quarterly',
    price: '$12.99/month',
    priceId: 'price_1QIt7t06yJLlYQYgIY3z5PXX',
    description: 'Save 13% vs monthly',
    commitment: 'Billed $38.97 quarterly',
    savings: 'Save 13%',
    recommended: true
  },
  {
    name: 'Annual',
    price: '$9.99/month',
    priceId: 'price_1QItAI06yJLlYQYg1mzUKLDw',
    description: 'Best value - Save 33%',
    commitment: 'Billed $119.88 annually',
    savings: 'Save 33%'
  }
];


export default function SubscriptionPlans() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const handleSubscribe = async (priceId) => {
    console.log('handleSubscribe called v2.1 with priceId:', priceId);
    
    if (!priceId) {
      console.error('No priceId provided');
      setError('Invalid subscription plan selected');
      return;
    }

    try {
      setError('');
      setLoading(true);

      // Define request parameters separately
      const requestData = {
        priceId: priceId,
        userId: user?.uid
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      };

      console.log('Making request with options:', {
        url: `${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`,
        options: { ...requestOptions, body: requestData }
      });

      // Make request
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/createCheckoutSession`,
        requestOptions
      );

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response error:', errorText);
        throw new Error(`Server error: ${errorText}`);
      }

      const data = await response.json();
      console.log('Server response data:', data);

      if (!data.sessionId) {
        throw new Error('No session ID received from server');
      }

      // Load Stripe
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      // Redirect to checkout
      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId: data.sessionId
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

    } catch (err) {
      console.error('Subscription error:', err);
      setError(err.message || 'Failed to start checkout process');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="subscription-container">
      {/* Version indicator */}
      <div style={{ position: 'fixed', bottom: 10, right: 10, fontSize: '10px', opacity: 0.5 }}>
        v2.1
      </div>

      <div className="subscription-card">
        <div className="subscription-header">
          <h2>Choose Your Plan</h2>
          <p>Enjoy a 14-day free trial on any subscription plan.</p>
        </div>

        {error && (
          <div className="error-message">{error}</div>
        )}

        <div className="subscription-plans">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`plan-card ${
                plan.recommended ? 'plan-card-recommended' : ''
              }`}
            >
              {plan.recommended && <div className="plan-label">Most Popular</div>}
              <h3 className="plan-name">{plan.name}</h3>
              <p className="plan-price">{plan.price}</p>
              <p className="plan-commitment">{plan.commitment}</p>
              <p className="plan-savings">{plan.savings}</p>

              <button
                onClick={() => handleSubscribe(plan.priceId)}
                disabled={loading}
                className="subscription-button"
              >
                {loading ? 'Processing...' : 'Start 14-Day Free Trial'}
              </button>
            </div>
          ))}
        </div>

        <div className="trial-details">
          <p>Free trial includes full access to all features.</p>
          <p>No credit card required to start. Cancel anytime during trial period.</p>
        </div>
      </div>
    </div>
  );
}