import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Trophy, Star, Diamond } from 'lucide-react';
import MagicElixirPlot from './MagicElixirPlot';
import DiamondStocksPlot from './DiamondStocksPlot';
import SimilarStocksTable from './SimilarStocksTable';
import D3RadarChart from './D3RadarChart';
import { Card, CardHeader, CardTitle, CardContent } from './CardComponent';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './TableComponent';

const formatMarketCap = (marketCap) => {
  if (marketCap >= 1e12) {
    return `$${(marketCap / 1e12).toFixed(2)}T`;
  } else if (marketCap >= 1e9) {
    return `$${(marketCap / 1e9).toFixed(2)}B`;
  } else if (marketCap >= 1e6) {
    return `$${(marketCap / 1e6).toFixed(2)}M`;
  } else {
    return `$${marketCap.toFixed(2)}`;
  }
};

const ScoreTooltip = ({ show, target }) => {
  if (!show || !target) return null;

  const rect = target.getBoundingClientRect();
  const tooltipStyle = {
    position: 'absolute',
    left: `${rect.left + rect.width / 2}px`,
    top: `${rect.bottom + window.scrollY}px`,
    transform: 'translateX(-50%)',
    zIndex: 1000,
  };

  return ReactDOM.createPortal(
    <div 
      className="p-3 bg-gray-800 text-white rounded shadow-lg max-w-xs text-sm"
      style={tooltipStyle}
    >
      The CANSLIM Score is a proprietary combined quantification of a stock's strength in all the different components of analysis deemed important in the strategy popularized by William J. O'Neil.
    </div>,
    document.body
  );
};

const StockRankings = ({ rankings }) => {
  if (!rankings) return null;

  // Function to interpolate between two colors
  const interpolateColor = (color1, color2, factor) => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };

  // Function to convert RGB array to CSS color string
  const rgbToString = (rgb) => `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.15)`;

  // Get background color based on percentile
  const getRowStyle = (percentile) => {
    // Define color stops (RGB values)
    const red = [239, 68, 68];     // rgb(239, 68, 68)   - red-500
    const yellow = [234, 179, 8];  // rgb(234, 179, 8)   - yellow-500
    const green = [34, 197, 94];   // rgb(34, 197, 94)   - green-500

    let color;
    if (percentile <= 50) {
      // Interpolate between red and yellow
      const factor = percentile / 50;
      color = interpolateColor(red, yellow, factor);
    } else {
      // Interpolate between yellow and green
      const factor = (percentile - 50) / 50;
      color = interpolateColor(yellow, green, factor);
    }

    return {
      backgroundColor: rgbToString(color)
    };
  };

  return (
    <div className="similar-stocks-container">
      <h3 className="similar-stocks-header">Overall Rankings</h3>
      <div className="similar-stocks-table">
        <table>
          <thead>
            <tr>
              <th>METRIC</th>
              <th>SCORE</th>
              <th>RANK</th>
              <th>PERCENTILE</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              className="percentile-row"
              style={getRowStyle(rankings.canslim.percentile)}
            >
              <td>
                <div className="metric-icon">
                  <Trophy className="w-4 h-4" />
                  CANSLIM
                </div>
              </td>
              <td>
                <span className="value">{rankings.canslim.score?.toFixed(2)}</span>
              </td>
              <td>
                <span className="value">
                  {rankings.canslim.rank} of {rankings.canslim.totalStocks}
                </span>
              </td>
              <td>
                <span className="value">{rankings.canslim.percentile?.toFixed(1)}%</span>
              </td>
            </tr>
            <tr 
              className="percentile-row"
              style={getRowStyle(rankings.magicElixir.percentile)}
            >
              <td>
                <div className="metric-icon">
                  <Star className="w-4 h-4" />
                  Magic Elixir
                </div>
              </td>
              <td>
                <span className="value">{rankings.magicElixir.score?.toFixed(2)}</span>
              </td>
              <td>
                <span className="value">
                  {rankings.magicElixir.rank} of {rankings.magicElixir.totalStocks}
                </span>
              </td>
              <td>
                <span className="value">{rankings.magicElixir.percentile?.toFixed(1)}%</span>
              </td>
            </tr>
            <tr 
              className="percentile-row"
              style={getRowStyle(rankings.diamond.percentile)}
            >
              <td>
                <div className="metric-icon">
                  <Diamond className="w-4 h-4" />
                  Diamond
                </div>
              </td>
              <td>
                <span className="value">{rankings.diamond.score?.toFixed(2)}</span>
              </td>
              <td>
                <span className="value">
                  {rankings.diamond.rank} of {rankings.diamond.totalStocks}
                </span>
              </td>
              <td>
                <span className="value">{rankings.diamond.percentile?.toFixed(1)}%</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StockDetail = () => {
  const { symbol } = useParams();
  const normalizedSymbol = symbol.toUpperCase();

  const [stock, setStock] = useState(null);
  const [rankings, setRankings] = useState(null);
  const [allStocks, setAllStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showScoreTooltip, setShowScoreTooltip] = useState(false);
  const scoreRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [
          allStocksResponse, 
          rankingResponse, 
          allScoredStocksResponse
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/getStocks`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/getRanking/${normalizedSymbol}`),
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/getAllScoredStocks`)
        ]);

        // Find the specific stock from the all stocks response
        const stockData = allStocksResponse.data.find(stock => stock.symbol === normalizedSymbol);
        if (!stockData) {
          throw new Error('Stock not found');
        }

        // Log the incoming data
        console.log('API Stock Response:', stockData);

        const rankingsData = rankingResponse.data;
        const currentStock = allScoredStocksResponse.data.find(s => s.symbol === normalizedSymbol);
  
        const rankings = {
          canslim: {
            score: stockData.canslimScore,
            rank: rankingsData.canslim.rank,
            totalStocks: rankingsData.canslim.totalStocks,
            percentile: rankingsData.canslim.percentile
          },
          magicElixir: {
            score: rankingsData.magicElixir.score,
            rank: rankingsData.magicElixir.rank,
            totalStocks: rankingsData.magicElixir.totalStocks,
            percentile: rankingsData.magicElixir.percentile
          },
          diamond: {
            score: rankingsData.diamond.score,
            rank: rankingsData.diamond.rank,
            totalStocks: rankingsData.diamond.totalStocks,
            percentile: rankingsData.diamond.percentile
          }
        };
  
        const combinedStockData = {
          ...stockData,
          Symbol: normalizedSymbol,
          symbol: normalizedSymbol,
          magicElixirScore: Number(currentStock?.magicElixirScore || 0),
          liquidityRating: Number(currentStock?.liquidityRating || 0),        
          floatScore: Number(currentStock?.floatScore || 0),
          volumeScore: Number(currentStock?.volumeScore || 0),
          diamondScore: Number(currentStock?.diamondScore || 0),
          currentPrice: Number(currentStock?.currentPrice || stockData['Current Price'] || 0),
          rsRating: Number(currentStock?.rsRating || stockData['RS Rating'] || 0),
          earningsGrowth: Number(currentStock?.earningsGrowth || stockData['EPS % Chg Last Qtr'] || 0),
          salesGrowth: Number(currentStock?.salesGrowth || stockData['Sales % Chg Lst Qtr'] || 0)
        };
  
        setStock(combinedStockData);
        setRankings(rankings);
        setAllStocks(allScoredStocksResponse.data);
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Failed to load data: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [normalizedSymbol]);

  if (isLoading) return <div className="p-4">Loading...</div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;
  if (!stock || !rankings) return <div className="p-4">No data found</div>;

  return (
    <div className="p-4">
      <Link 
        to="/" 
        className="mb-4 inline-block text-blue-500 hover:text-blue-700"
      >
        &larr; Back to Stock List
      </Link>
      
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">{stock.Symbol} - {stock.Name}</h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="similar-stocks-container">
  <h3 className="similar-stocks-header">Stock Information</h3>
  <div className="similar-stocks-table">
    <table>
      <tbody>
        <tr>
          <td>Name</td>
          <td><span className="value">{stock.Name}</span></td>
        </tr>
        <tr>
          <td>Symbol</td>
          <td><span className="value">{stock.Symbol}</span></td>
        </tr>
        <tr>
          <td>Market Cap</td>
          <td><span className="value">{formatMarketCap(stock.MarketCapMil * 1e6)}</span></td>
        </tr>
        <tr>
          <td>Market Cap Type</td>
          <td><span className="value">{stock.CapType}</span></td>
        </tr>
        <tr>
          <td>Current Price</td>
          <td><span className="value">${parseFloat(stock.currentPrice).toFixed(2)}</span></td>
        </tr>
        <tr>
        </tr>
      </tbody>
    </table>
  </div>
</div>

          <StockRankings rankings={rankings} />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="similar-stocks-container">
          <h3 className="similar-stocks-header">CANSLIM Analysis</h3>
          <div className="p-4">
            <D3RadarChart 
              data={[
                { category: 'C', value: stock.c },
                { category: 'A', value: stock.a },
                { category: 'N', value: stock.n },
                { category: 'S', value: stock.s },
                { category: 'L', value: stock.l },
                { category: 'I', value: stock.i }
              ]}
              width={400}
              height={400}
            />
          </div>
        </div>

        <div className="space-y-6">
<MagicElixirPlot 
  symbol={normalizedSymbol} 
  stockData={{
    rsRating: stock.rsRating,
    earningsGrowth: stock.earningsGrowth,
    salesGrowth: stock.salesGrowth,
    currentPrice: stock.currentPrice,
    sharesFloat: stock['Shares in Float (1000s)'],
    volume: stock['Volume (1000s)'],
    magicElixirScore: stock.magicElixirScore,
    liquidityRating: stock.liquidityRating,
    floatScore: stock.floatScore,
    volumeScore: stock.volumeScore
  }}
  rankings={rankings}  // Add this line
/>
        </div>
        </div>
      <div className="mb-8">
        <DiamondStocksPlot 
          symbol={normalizedSymbol} 
          stockData={{
            canslimScore: stock.canslimScore,
            magicElixirScore: stock.magicElixirScore,
            currentPrice: stock.currentPrice,
            rsRating: stock.rsRating,
            earningsGrowth: stock.earningsGrowth,
            salesGrowth: stock.salesGrowth,
            liquidityRating: stock.liquidityRating,
            diamondScore: stock.diamondScore
          }}
          thresholds={stock.thresholds}
          rankings={rankings}
        />
      </div>

      {allStocks && allStocks.length > 0 && (
        <div className="mb-8">
          <SimilarStocksTable 
            symbol={symbol}
            stockData={{
              canslimScore: stock.canslimScore,
              magicElixirScore: stock.magicElixirScore,
              currentPrice: stock.currentPrice,
              rsRating: stock.rsRating,
              earningsGrowth: stock.earningsGrowth,
              salesGrowth: stock.salesGrowth,
              liquidityRating: stock.liquidityRating,
              diamondScore: stock.diamondScore,
              floatScore: stock.floatScore,
              volumeScore: stock.volumeScore
            }}
            allStocks={allStocks}
            rankings={rankings}
          />
        </div>
      )}

      <ScoreTooltip show={showScoreTooltip} target={scoreRef.current} />
    </div>
  );
};

const calculatePercentile = (values, percentile) => {
  const sorted = [...values].sort((a, b) => a - b);
  const index = Math.ceil((percentile / 100) * sorted.length) - 1;
  return sorted[index];
};

export default StockDetail;