import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from './contexts/AuthContext';

const ContactForm = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    setError('');

    try {
      const functions = getFunctions();
      const sendContactEmail = httpsCallable(functions, 'sendContactEmail');

      await sendContactEmail({
        subject: formData.subject,
        message: formData.message
      });

      setStatus('sent');
      setTimeout(() => {
        navigate('/canslim-stocks');
      }, 2000);

    } catch (error) {
      console.error('Error sending message:', error);
      setError('There was an error sending your message. Please try again.');
      setStatus('idle');
    }
  };

  return (
    <div className="subscription-container">
      <div className="subscription-card max-w-2xl">
        <div className="subscription-header">
          <h2>Contact Support</h2>
          <p>Send us a message and we'll get back to you as soon as possible.</p>
        </div>
        
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              type="text"
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
              placeholder="How can we help?"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              className="min-h-[120px] p-3 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Tell us more about your inquiry..."
              required
            />
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            
            <button
              type="submit"
              disabled={status !== 'idle'}
              className="login-button inline-flex items-center"
            >
              {status === 'idle' && (
                <>
                  <Send className="h-4 w-4 mr-2" />
                  Send Message
                </>
              )}
              {status === 'sending' && 'Sending...'}
              {status === 'sent' && 'Message Sent!'}
            </button>
          </div>

          {status === 'sent' && (
            <div className="success-message mt-4">
              Your message has been sent successfully! We'll get back to you soon.
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;