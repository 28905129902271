import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updatePassword as firebaseUpdatePassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  setDoc, 
  onSnapshot 
} from 'firebase/firestore';

const db = getFirestore();
const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);

  console.log("AuthProvider rendering - user:", user, "loading:", loading);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("onAuthStateChanged triggered - user:", user);
      
      // If no user is signed in, set loading to false and proceed to the login screen
      if (!user) {
        console.log("No user detected, setting user and subscription to null");
        setUser(null);
        setSubscription(null);
        setLoading(false);
        return;
      }

      // User is signed in, proceed with Firestore data fetching
      console.log("User detected:", user);
      setLoading(true); // Ensure loading is true during data fetching

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        console.log("Fetched user data from Firestore:", userData);

        setUser({
          ...user,
          subscriptionData: userData?.subscription || null
        });

        if (userData) {
          // Set up real-time listener for subscription changes
          const unsubscribeSubscription = onSnapshot(
            doc(db, 'users', user.uid),
            (doc) => {
              const data = doc.data();
              console.log("Subscription data updated in Firestore:", data?.subscription);
              setSubscription(data?.subscription || null);
            }
          );

          // Cleanup subscription listener on user change or unmount
          return () => {
            console.log("Cleaning up subscription listener");
            unsubscribeSubscription();
          };
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);  // Ensure loading is set to false after data fetching completes
      }
    });

    // Cleanup onAuthStateChanged listener on component unmount
    return () => {
      console.log("Cleaning up onAuthStateChanged listener");
      unsubscribe();
    };
  }, []);

  const signup = async (email, password) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        createdAt: new Date().toISOString(),
        subscription: null
      });
      console.log("User signed up and Firestore document created:", user);
      return { user };
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  };

  const login = (email, password) => signInWithEmailAndPassword(auth, email, password);
  const logout = () => signOut(auth);

  const updatePassword = async (newPassword) => {
    if (!auth.currentUser) throw new Error('No user logged in');
    return firebaseUpdatePassword(auth.currentUser, newPassword);
  };

  const resetPassword = (email) => sendPasswordResetEmail(auth, email);

  const updateSubscription = async (subscriptionData) => {
    if (!user) throw new Error('No user logged in');
    try {
      await setDoc(doc(db, 'users', user.uid), {
        subscription: subscriptionData
      }, { merge: true });
      console.log("Subscription updated in Firestore:", subscriptionData);
    } catch (error) {
      console.error('Error updating subscription:', error);
      throw error;
    }
  };

  const isTrialing = () => subscription?.status === 'trialing';
  const isSubscribed = () => ['active', 'trialing'].includes(subscription?.status);

  const value = {
    user,
    loading,
    subscription,
    signup,
    login,
    logout,
    updatePassword,
    resetPassword,
    updateSubscription,
    isTrialing,
    isSubscribed
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
